import { clsx } from 'clsx'
import { ReactNode } from 'react'

export type BadgeStyle = 'black' | 'blue' | 'grey' | 'green' | 'pink' | 'red'

const Badge = ({
  badgeStyle,
  children,
}: {
  badgeStyle: BadgeStyle
  children: ReactNode
}) => {
  return (
    <div
      className={clsx('inline-flex rounded px-2 py-0.5 text-xs', {
        'bg-black-901 font-bold text-white-900': badgeStyle === 'black',
        'bg-grey-903': badgeStyle === 'grey',
        'bg-red-901 font-bold text-white-900': badgeStyle === 'red',
        'bg-green-907 font-bold text-white-900': badgeStyle === 'green',
        'bg-blue-902 font-bold text-white-900': badgeStyle === 'blue',
        'bg-pink-600 font-bold text-white-900': badgeStyle === 'pink',
      })}
    >
      {children}
    </div>
  )
}

export default Badge
