import { arrayMove } from '@dnd-kit/sortable'
import {
  MealSummary,
  MealSwap,
  TermSubTerm,
  useTerm,
} from '@tovala/browser-apis-combinedapi'
import {
  getMenuMealComponents,
  makeMenuComponentsStandardized,
  MenuComponentStandardized,
  MenuComponentStandardizedTextImageStack,
} from '@tovala/browser-apis-menu-components'
import { MenuComponents } from '@tovala/browser-apis-menu-delivery'
import { useTermMealSummaries } from 'hooks/combinedAPI/menus'
import { findIndex, flatMap, isEqual, orderBy } from 'lodash-es'
import { useCallback, useReducer, useState } from 'react'
import { getComponentTempID, validateMealSwaps } from './utils'

import { produce } from 'immer'

interface Menu {
  components: MenuComponentStandardized[]
  description: string
  id: string
  isDefault: boolean
  serverComponents: MenuComponentStandardized[]
  subTermID: string
}

type Action =
  | { type: 'add'; menu: Menu; menuID: string }
  | { type: 'remove'; menuID: string }
  | { type: 'replaceAll'; menus: MenusState }
  | { type: 'update'; menu: Partial<Menu>; menuID: string }

export type MenusState = Record<string, Menu>

function reducer(draft: MenusState, action: Action) {
  switch (action.type) {
    case 'add': {
      const { menu, menuID } = action
      draft[menuID] = menu
      break
    }
    case 'remove': {
      const { menuID } = action
      delete draft[menuID]
      break
    }
    case 'replaceAll': {
      const { menus } = action
      return menus
    }
    case 'update': {
      const { menu, menuID } = action
      draft[menuID] = Object.assign(draft[menuID], menu)
      break
    }

    default:
      break
  }
}
const curriedReducer = produce(reducer)

function useMenus({ initialMenus }: { initialMenus: MenusState }) {
  const [menus, dispatch] = useReducer(curriedReducer, initialMenus)

  return {
    add: useCallback(({ menu, menuID }: { menu: Menu; menuID: string }) => {
      dispatch({
        type: 'add',
        menu,
        menuID,
      })
    }, []),
    remove: useCallback(({ menuID }: { menuID: string }) => {
      dispatch({
        type: 'remove',
        menuID,
      })
    }, []),
    replaceAll: useCallback(({ menus }: { menus: MenusState }) => {
      dispatch({
        type: 'replaceAll',
        menus,
      })
    }, []),
    update: useCallback(
      ({ menu, menuID }: { menu: Partial<Menu>; menuID: string }) => {
        dispatch({
          type: 'update',
          menu,
          menuID,
        })
      },
      []
    ),
    menus,
  }
}

export function useMenuEditor({
  orderedSubTerms,
  initialMenus,
  termMealSummaries,
}: {
  orderedSubTerms: TermSubTerm[]
  initialMenus: MenusState
  termMealSummaries: MealSummary[][]
}) {
  const { add, remove, replaceAll, update, menus } = useMenus({ initialMenus })

  const [selectedMenuID, setSelectedMenuID] = useState<string>(
    Object.values(initialMenus)[0].id
  )
  const selectedMenu = selectedMenuID ? menus[selectedMenuID] : null

  return {
    addComponent: ({
      component,
      index,
      menuID,
    }: {
      component: MenuComponentStandardized
      index: number
      menuID: string
    }) => {
      const updatedComponents = produce(menus[menuID].components, (draft) => {
        draft.splice(index, 0, component)

        // Remove textImageStack children components from main components list
        if (
          component.type === 'textImageStack' &&
          component.properties.children.length
        ) {
          component.properties.children.forEach((child) => {
            const childIndex = findIndex(
              draft,
              (component) => component.id === child.id
            )
            if (childIndex !== -1) {
              draft.splice(childIndex, 1)
            }
          })
        }
      })

      update({
        menuID,
        menu: {
          components: updatedComponents,
        },
      })
    },
    addMenu: ({ menu, menuID }: { menu: Menu; menuID: string }) => {
      add({
        menu,
        menuID,
      })
    },
    deleteMenu: remove,
    makeMenuComponents: ({
      mealIDs,
      mealSwaps,
      subTermID,
    }: {
      mealIDs: number[]
      mealSwaps: MealSwap[]
      subTermID: string
    }) => {
      const subTerm = orderedSubTerms.find(
        (subTerm) => subTerm.id === subTermID
      )

      if (!subTerm) {
        return []
      }

      const subTermIndex = orderedSubTerms.findIndex(
        (orderedSubTerm) => orderedSubTerm.id === subTerm.id
      )

      if (subTermIndex === -1) {
        return []
      }

      return makeMenuComponentsStandardized({
        meals: mealIDs
          .map((mealID) => {
            return termMealSummaries[subTermIndex].find(
              (meal) => meal.id === mealID
            )
          })
          .filter((mealSummary): mealSummary is MealSummary => !!mealSummary),
        mealSwaps,
        suggestions: undefined,
        specialEvent: subTerm.specialEvent, // TODO || term.specialEvent
      })
    },
    menus,
    onChangeSelectedMenu: (menuID: string) => {
      setSelectedMenuID(menuID)
    },
    reorderComponents: (
      activeIndex: number,
      overIndex: number,
      menuID: string
    ) => {
      update({
        menuID,
        menu: {
          components: arrayMove(
            [...menus[menuID].components],
            activeIndex,
            overIndex
          ),
        },
      })
    },
    removeComponent: ({
      component,
      menuID,
    }: {
      component: MenuComponentStandardized
      menuID: string
    }) => {
      const componentIndex = findIndex(
        menus[menuID].components,
        (c) => c.id === component.id
      )

      if (componentIndex !== -1) {
        const updatedComponents = produce(menus[menuID].components, (draft) => {
          draft.splice(componentIndex, 1)

          // Return children to components list if removed component was textImageStack
          if (
            component.type === 'textImageStack' &&
            component.properties.children.length
          ) {
            draft.splice(componentIndex, 0, ...component.properties.children)
          }
        })

        update({
          menu: {
            components: updatedComponents,
          },
          menuID,
        })
      }
    },
    replaceAllMenus: ({ menus }: { menus: MenusState }) => {
      replaceAll({ menus })
    },
    selectedMenu,
    updateComponent: ({
      component,
      previousChildren,
      menuID,
    }: {
      component: MenuComponentStandardized
      previousChildren?: MenuComponentStandardizedTextImageStack['properties']['children']
      menuID: string
    }) => {
      const componentIndex = findIndex(
        menus[menuID].components,
        (c) => c.id === component.id
      )

      if (componentIndex !== -1) {
        const updatedComponents = produce(menus[menuID].components, (draft) => {
          draft[componentIndex] = component

          if (component.type === 'textImageStack') {
            // Remove any new included children from the main component order
            if (component.properties.children) {
              component.properties.children.forEach((child) => {
                const childIndex = findIndex(
                  draft,
                  (component) => component.id === child.id
                )
                if (childIndex !== -1) {
                  draft.splice(childIndex, 1)
                }
              })
            }

            // Return any removed children back to the main component order
            if (previousChildren) {
              const childrenIDs = component.properties.children.map(
                (child) => child.id
              )

              draft.splice(
                componentIndex + 1,
                0,
                ...previousChildren.filter(
                  (prevChild) => !childrenIDs.includes(prevChild.id)
                )
              )
            }
          }
        })

        update({
          menu: {
            components: updatedComponents,
          },
          menuID,
        })
      }
    },
    updateMenu: update,
  }
}

export function useOrderedSubTerms({ termID }: { termID: number | undefined }) {
  const { data: term } = useTerm({ termID })
  const subTerms = term?.subTerms ?? []

  const orderedSubTerms = orderBy(subTerms, ['facilityNetwork', 'shipPeriod'])

  return {
    orderedSubTerms,
    term,
  }
}

export function useTermResolvedMenuComponents({
  orderedSubTerms,
  termDefaultMenus,
  termSpecialEvent,
}: {
  orderedSubTerms: TermSubTerm[]
  termDefaultMenus: MenuComponents[]
  termSpecialEvent: string | undefined
}) {
  const termMealSummaries = useTermMealSummaries({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })

  const isLoadingTermMealSummaries = termMealSummaries.some(
    (termMealSummaries) => termMealSummaries.isLoading
  )

  const termResolvedMenuComponents = orderedSubTerms.map((subTerm, index) => {
    const mealSwaps = subTerm.defaultMenu.mealSwaps ?? []
    const meals = termMealSummaries[index]?.data ?? []
    let menuComponents = termDefaultMenus[index]?.components ?? []

    const specialEvent = subTerm.specialEvent || termSpecialEvent

    const mealSwapsMatch =
      menuComponents.length > 0
        ? validateMealSwaps({ components: menuComponents, subTerm })
        : true

    if (!mealSwapsMatch) {
      const mealSwapIDs = flatMap(
        mealSwaps.map((mealSwap) => [mealSwap.mealID, ...mealSwap.swapIDs])
      )

      menuComponents = menuComponents.filter((component) => {
        // Discard meal component if meal exists in mealSwaps
        if (
          component.type === 'meal' &&
          mealSwapIDs.includes(component.properties.mealID)
        ) {
          return false
        } else if (component.type === 'mealWithExtra') {
          const mealSwap = mealSwaps.find(
            (mealSwap) => mealSwap.mealID === component.properties.meal.mealID
          )

          // Discard mealWithExtra component if there's no matching mealSwap pair
          if (
            !mealSwap ||
            mealSwap.swapIDs[0] !== component.properties.mealOption.meal.mealID
          ) {
            return false
          }
          return true
        } else if (component.type === 'animatedMealCarousel') {
          const mealSwap = mealSwaps.find(
            (mealSwap) =>
              mealSwap.mealID === component.properties.mealOptions[0].mealID
          )
          const mealSwapIDs = mealSwap
            ? [mealSwap.mealID, ...mealSwap.swapIDs]
            : []
          // Discard animatedMealCarousel if there's no matching mealSwap group
          if (
            !mealSwap ||
            !isEqual(
              mealSwapIDs,
              component.properties.mealOptions.map((option) => option.mealID)
            )
          ) {
            return false
          }

          return true
        }
        return true
      })
    }

    const components = getMenuMealComponents({
      mealSwaps,
      meals: orderBy(meals, 'mainDisplayOrder'),
      menuComponents,
      specialEvent,
      suggestions: undefined,
    })

    return components.map((component) => {
      const menuComponent = menuComponents.find(
        (menuComponent) => menuComponent.id === component.id
      )
      return menuComponent
        ? component
        : {
            ...component,
            id: `${getComponentTempID(component)}-${subTerm.id}`,
          }
    })
  })

  return {
    termResolvedMenuComponents: !isLoadingTermMealSummaries
      ? termResolvedMenuComponents
      : [],
  }
}
