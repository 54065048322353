import {
  DndContext,
  DragOverlay,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  Term,
  TermMenu,
  TermMenuMeal,
  useUpdateMenuMeals,
} from '@tovala/browser-apis-combinedapi'
import {
  APIErrorDisplay,
  Button,
  ButtonLoading,
  Modal,
  ModalHeader,
} from '@tovala/component-library'
import { successHandler } from 'actions/notifications'
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'
import { ModalBody } from 'components/modals/Modal'
import { useAppDispatch } from 'hooks'
import { cloneDeep, findIndex, flatMap } from 'lodash-es'
import { ReactNode, useState } from 'react'
import { UPDATE_MENU_MEALS_ERRORS } from 'utils/errors'

const MealsLayoutEditor = ({ term }: { term: Term }) => {
  const dispatch = useAppDispatch()

  const {
    mutate: updateMenuMeals,
    isLoading: isUpdatingMenuMeals,
    error: updateMenuMealsError,
    isError: hasUpdateMenuMealsError,
  } = useUpdateMenuMeals()

  const orderedMenus = flatMap(
    term.subTerms.map((subTerm) => subTerm.menus.map((menu) => menu)) ?? []
  ).sort((a, b) => a.name.localeCompare(b.name))

  const {
    onChangeSelectedMenuIndex,
    onCopyChicago1,
    orderedMeals,
    reorderMeals,
    selectedMeals,
  } = useOrderedLayoutForMenu({
    orderedMenus,
  })

  const [showCopiedBanner, setShowCopiedBanner] = useState(false)
  const [showCopyConfirmationDialog, setShowCopyConfirmationDialog] =
    useState(false)
  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(selectedMeals, (meal) => meal.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1
  const activeMeal = selectedMeals.find((meal) => meal.id === activeID)

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  const saveMealDisplayOrders = () => {
    for (const [menuID, meals] of Object.entries(orderedMeals)) {
      const updatedMeals = meals
        .map((meal) => {
          return {
            menuID,
            mealID: meal.id,
            mainDisplayOrder: meal.mainDisplayOrder,
            productionCode: meal.productionCode,
            expirationDate: meal.expirationDate,
          }
        })
        .filter((meal) => meal.mealID !== 0)

      updateMenuMeals(
        { data: updatedMeals, menuID },
        {
          onSuccess: () => {
            successHandler(dispatch, 'Success! Meal Display Orders saved.')
          },
        }
      )
    }
  }

  return (
    <div className="space-y-6 mb-20">
      <div className="flex justify-between items-center">
        <h1 className="text-k/28_110">Meals Preview</h1>
        <div className="flex space-x-4">
          <Button
            buttonStyle="stroke"
            onClick={() => {
              setShowCopyConfirmationDialog(true)
              setShowCopiedBanner(false)
            }}
            size="medium"
          >
            Copy chicago 1
          </Button>

          <ButtonLoading
            isLoading={isUpdatingMenuMeals}
            onClick={() => {
              setShowCopiedBanner(false)
              saveMealDisplayOrders()
            }}
            size="medium"
          >
            Save Meal Display Orders
          </ButtonLoading>
        </div>
      </div>

      {hasUpdateMenuMealsError && (
        <APIErrorDisplay
          error={updateMenuMealsError}
          errorCodeMessageMap={UPDATE_MENU_MEALS_ERRORS}
        />
      )}

      {showCopiedBanner && <DisplayOrderCopiedBanner />}

      {showCopyConfirmationDialog && (
        <DisplayOrderCopyConfirmationDialog
          onClickClose={() => {
            setShowCopyConfirmationDialog(false)
          }}
          onClickConfirm={() => {
            onCopyChicago1()
            setShowCopiedBanner(true)
            setShowCopyConfirmationDialog(false)
          }}
        />
      )}

      <DndContext
        onDragEnd={({ over }) => {
          setActiveID(null)
          if (over) {
            const overIndex = getIndex(over.id)
            if (activeIndex !== overIndex) {
              reorderMeals(activeIndex, overIndex)
            }
          }
        }}
        onDragOver={({ over }) => {
          if (!over) {
            return
          }
        }}
        onDragStart={({ active }) => {
          if (!active) {
            return
          }

          setActiveID(active.id)
        }}
        sensors={sensors}
      >
        <TabGroup onChange={(index) => onChangeSelectedMenuIndex(index)}>
          <TabList>
            {orderedMenus.map((menu) => {
              return <Tab key={menu.id}>{menu.name}</Tab>
            })}
          </TabList>

          <TabPanels>
            {orderedMenus.map((menu) => {
              return (
                <TabPanel key={menu.id}>
                  <div className="space-y-8">
                    <MealsGrid
                      activeID={activeID}
                      meals={orderedMeals[menu.id]}
                    />
                  </div>
                </TabPanel>
              )
            })}
          </TabPanels>
        </TabGroup>

        <DragOverlay>
          {activeID && activeMeal ? <Meal meal={activeMeal} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

export default MealsLayoutEditor

const MealsGrid = ({
  activeID,
  meals,
}: {
  activeID: UniqueIdentifier | null
  meals: TermMenuMeal[]
}) => {
  return (
    <SortableContext items={meals} strategy={rectSortingStrategy}>
      <div className="grid grid-cols-3 gap-3">
        {meals.map((meal, index) => {
          return (
            <SortableComponent
              key={meal.id}
              isDragging={activeID === meal.id}
              mealID={meal.id}
            >
              <div className="relative">
                <div className="absolute top-2 font-bold right-2 bg-white rounded-lg py-2 px-4 bg-opacity-70">
                  {index + 1}
                </div>
                <Meal meal={meal} />
              </div>
            </SortableComponent>
          )
        })}
      </div>
    </SortableContext>
  )
}

const Meal = ({ meal }: { meal: TermMenuMeal }) => {
  const image = meal.images.find((image) => image.key === 'cell_tile')

  return (
    <div className="pb-6">
      <div className="w-full aspect-3/4 bg-grey-5 rounded-md">
        <img
          alt=""
          className="w-full h-full object-cover rounded-md"
          src={image?.url}
        />
      </div>
      <h3 className="font-bold mt-2">{meal.title}</h3>
      <p className="text-body-sm">{meal.subtitle}</p>
    </div>
  )
}

const DisplayOrderCopiedBanner = () => {
  return (
    <div className="rounded-2xl bg-green-905 bg-opacity-30 space-y-1 p-4">
      <div>
        <p className="font-bold">Copied!</p>
        <p>
          Make sure to check the other menus are ordered as expected and{' '}
          <b>save Meal Display Orders</b> before leaving the page.
        </p>
      </div>
    </div>
  )
}

const DisplayOrderCopyConfirmationDialog = ({
  onClickClose,
  onClickConfirm,
}: {
  onClickClose(): void
  onClickConfirm(): void
}) => {
  return (
    <Modal onCloseModal={onClickClose}>
      <ModalBody>
        <ModalHeader onClickClose={onClickClose}>
          Copy Chicago 1 Display Order to Menus
        </ModalHeader>
        <div className="max-w-[645px] w-full">
          <div className="space-y-4 p-4 pb-12">
            <p>Note:</p>
            <ul className="list-disc pl-4">
              <li>
                Any Chicago 1 meals that are not available on another menu will
                be excluded from that menu's display orders.
              </li>
              <li>
                Any additional meals on another menu that are not on Chicago 1
                will be added to the beginning of that menu's display order.
              </li>
            </ul>

            <p>
              Are you sure you want to copy Chicago 1 Meal Display Order to the
              other available menus on this term?
            </p>
          </div>
          <div className="flex items-center justify-between">
            <Button buttonStyle="stroke" onClick={onClickClose} size="large">
              Cancel
            </Button>
            <Button onClick={onClickConfirm} size="large">
              Yes, Copy Chicago 1
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

const SortableComponent = ({
  children,
  mealID,
  isDragging,
}: {
  children: ReactNode
  mealID: number
  isDragging: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: mealID,
    })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className="h-full w-full cursor-grab"
      style={style}
      {...attributes}
      {...listeners}
    >
      {!isDragging && children}
    </div>
  )
}

interface Menus {
  [menuID: string]: TermMenuMeal[]
}

function useOrderedLayoutForMenu({
  orderedMenus,
}: {
  orderedMenus: TermMenu[]
}) {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0)
  const selectedMenu = orderedMenus[selectedMenuIndex]

  const [orderedMeals, setOrderedMeals] = useState<Menus>(() => {
    return Object.fromEntries(
      orderedMenus.map((menu) => {
        const meals = [...menu.meals].sort(
          (a, b) => a.mainDisplayOrder - b.mainDisplayOrder
        )
        return [menu.id, meals]
      })
    )
  })

  const selectedMeals = orderedMeals[selectedMenu.id]

  return {
    orderedMeals,
    onChangeSelectedMenuIndex: (index: number) => {
      setSelectedMenuIndex(index)
    },
    onCopyChicago1: () => {
      const chicago1Index = 0
      const chicago1Menu = orderedMenus[chicago1Index]
      setOrderedMeals((prevState) => {
        const updatedOrderedMenus = cloneDeep(prevState)

        orderedMenus.forEach((menu, index) => {
          if (index !== chicago1Index) {
            const updatedMeals = [...updatedOrderedMenus[menu.id]].map(
              (meal) => ({ ...meal })
            )

            updatedOrderedMenus[menu.id] = updatedMeals
              .map((meal) => {
                const chicago1MealDisplayOrder =
                  updatedOrderedMenus[chicago1Menu.id].find(
                    (c1Meal) => c1Meal.id === meal.id
                  )?.mainDisplayOrder ?? 0

                return {
                  ...meal,
                  mainDisplayOrder: chicago1MealDisplayOrder,
                }
              })
              .sort((a, b) => a.mainDisplayOrder - b.mainDisplayOrder)
          }
        })

        return updatedOrderedMenus
      })
    },
    reorderMeals: (activeIndex: number, overIndex: number) => {
      const updatedMeals = [...selectedMeals].map((meal) => ({ ...meal }))

      const reorderedMeals = reorderMeals(updatedMeals, activeIndex, overIndex)

      const mealIDs = updatedMeals.map((meal) => meal.id)
      reorderedMeals.forEach((meal, index) => {
        const mealIndex = mealIDs.indexOf(meal.id)

        const updatedOrder = index + 1
        updatedMeals[mealIndex].mainDisplayOrder = updatedOrder
      })

      const updatedMenus = { ...orderedMeals }
      updatedMenus[selectedMenu.id] = updatedMeals.sort(
        (a, b) => a.mainDisplayOrder - b.mainDisplayOrder
      )

      setOrderedMeals(updatedMenus)
    },
    selectedMeals,
    selectedMenu,
    selectedMenuIndex,
  }
}

const reorderMeals = (
  list: TermMenuMeal[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}
