import {
  Modal,
  ModalHeader,
  Button,
  ButtonLoading,
} from '@tovala/component-library'

export interface DialogData {
  menus: {
    description: string
    errors: string[]
    mealIDs: number[]
  }[]
  onSubmit(): void
  updateType: 'create' | 'update'
}

const UpdateMenusDialog = ({
  data,
  onClose,
}: {
  data: DialogData
  onClose(): void
}) => {
  const { menus, onSubmit, updateType } = data

  const disableCreate = menus.some((menu) => menu.errors.length > 0)

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>{updateType} Menus</ModalHeader>
      <div className="space-y-10 p-6 w-full max-w-[700px]">
        <div className="space-y-4">
          {menus.map((menu) => {
            return (
              <div key={menu.description}>
                <div className="font-bold">
                  {menu.description} ({menu.mealIDs.length} meals)
                </div>
                {menu.errors.length > 0 && (
                  <ul className="pl-4 list-disc text-red-901 whitespace-pre-wrap">
                    {menu.errors.map((error, index) => {
                      return (
                        <li key={`${menu.description}${index}`}>{error}</li>
                      )
                    })}
                  </ul>
                )}
              </div>
            )
          })}
        </div>
        <div className="flex justify-between space-x-8">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>
          <ButtonLoading
            buttonStyle="dark"
            disabled={disableCreate}
            isLoading={false}
            onClick={() => {
              onSubmit()
            }}
            size="large"
          >
            <span className="capitalize">{updateType} Menus</span>
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateMenusDialog
