import { debounce, isEmpty } from 'lodash-es'
import {
  ErrorCodeMessageMapCombinedAPI,
  Term,
  useCreateMenuSideSwaps,
  useCreateTermWorkingItems,
  useInvalidateListingsAdmin,
  useInvalidateTerms,
  useTerm,
  useUpdateMenuMeals,
} from '@tovala/browser-apis-combinedapi'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import AlertInline from 'components/common/AlertInline'
import FileDropzone from '../common/FileDropzone'
import Loader from 'components/common/Loader'
import {
  APIErrorDisplay,
  ButtonLoading,
  Checkbox,
} from '@tovala/component-library'
import {
  getMenuMainDisplayOrders,
  getMenuSideSwaps,
  getTPSOTMeals,
  TPOSTMeal,
} from 'utils/parseTPSOT'
import {
  CREATE_MENU_SIDE_SWAPS_ERRORS,
  UPDATE_MENU_MEALS_ERRORS,
} from 'utils/errors'

/*
  Example meal doc
  https://docs.google.com/spreadsheets/d/1IlQmqASIdWO5fkIpdw1x7NBWW8_FRiyrOki9OZ9kcPE/edit#gid=1177790863
*/

interface ImportedMealData {
  expirationDate: string
  facilityNetworks: string[]
  menuIDs: string[]
  misevalaMealVersionID: string
  productionCode: number
  termid: number
  type: string
  workingTitle: string
  version: string
}

interface MealData {
  expirationDate: string
  menuIDs: string[]
  misevalaMealVersionID: string
  productionCode: number
  termid: number
  title: string
}

const LOAD_TERM_ERRORS: ErrorCodeMessageMapCombinedAPI = {
  Fallback: {
    helpToFix: 'Please reload the page to try again.',
    whatHappened: 'Unable to Load Term',
    why: "We couldn't load the term due to a technical issue on our end.",
  },
}

const ImportTermMeals = () => {
  const { termid: termIDParam } = useParams()

  const termID = termIDParam ? Number.parseInt(termIDParam, 10) : undefined

  const { invalidateListings } = useInvalidateListingsAdmin()
  const { invalidateTerm } = useInvalidateTerms()

  const {
    isLoading: isLoadingTerm,
    error: loadTermError,
    isError: hasLoadTermError,
    data: term,
  } = useTerm({ termID })

  const { isLoading, mutate } = useCreateTermWorkingItems()
  const createTermWorkingItems = debounce(mutate, 250)

  const [isSaved, setIsSaved] = useState(false)
  const [meals, setMeals] = useState<ImportedMealData[]>()
  const [tpsotMeals, setTPSOTMeals] = useState<{
    chicago: TPOSTMeal[]
    slc: TPOSTMeal[]
  }>()
  const [selectedItems, setSelectedItems] = useState(new Set<number>())

  const handleImportMeals = async (files: File[]) => {
    if (!term) {
      return
    }

    const { mealsToCreate, tpsotMeals } = await getTPSOTMeals({
      file: files[0],
      term,
    })

    setMeals(mealsToCreate)
    setTPSOTMeals(tpsotMeals)
  }

  const createMeals = (meals: ImportedMealData[]) => {
    let allMeals: MealData[] = meals.map((meal) => {
      return {
        title: `WT: ${meal.workingTitle}`,
        productionCode: meal.productionCode,
        termid: meal.termid,
        expirationDate: meal.expirationDate,
        menuIDs: meal.menuIDs,
        misevalaMealVersionID: meal.misevalaMealVersionID,
      }
    })

    if (selectedItems.size > 0) {
      allMeals = allMeals.filter((_meal, index) => {
        return selectedItems.has(index)
      })
    }

    const data = {
      meals: allMeals,
    }
    createTermWorkingItems(
      { data },
      {
        onSuccess: () => {
          setIsSaved(true)
          invalidateListings()
          termID && invalidateTerm(termID)
        },
      }
    )
  }

  if (isLoadingTerm) {
    return <Loader />
  }

  if (hasLoadTermError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError}
        errorCodeMessageMap={LOAD_TERM_ERRORS}
      />
    )
  }

  const missingDefaultMenus =
    term && term.subTerms.some((subTerm) => subTerm.defaultMenu === null)

  return (
    <div className="mb-5 space-y-8 font-sans-new pb-20">
      <h1 className="text-k/36_110">
        Term #{term?.id && <span>{term.id}</span>} - Create Meals and Extras
      </h1>

      {term && (
        <div className="space-y-1">
          <p>1. Drop Term Planning SoT CSV file into box below.</p>
          <p>
            2. Review data - working titles, production codes, and facility
            networks will be displayed.
          </p>
          <p>
            3. Click &quot;Create Meals and Extras&quot; to create the meals and
            extras in the database and added to the appropriate menus.
          </p>

          {missingDefaultMenus ? (
            <AlertInline alertStyle="danger">
              <strong>Missing Default Menus</strong> Default menus are required
              to create Term Meals and Extras. Please contact #help-backend.
            </AlertInline>
          ) : (
            <div>
              <FileDropzone
                accept={{ 'text/csv': ['.csv'] }}
                maxFiles={1}
                onDrop={handleImportMeals}
              />
            </div>
          )}
        </div>
      )}

      {meals && (
        <div>
          <h2 className="text-k/26_110">Meals and Extras</h2>

          {!isSaved ? (
            <>
              <div className="space-y-2">
                {term.meals.length > 0 ? (
                  <>
                    <b>Select the Meals and/or Extras to create</b>
                    {meals.map((meal, index) => {
                      return (
                        <Checkbox
                          key={`${meal.workingTitle}${meal.version}`}
                          checked={selectedItems.has(index)}
                          label={
                            <MenuItem
                              key={`${meal.workingTitle}${meal.version}`}
                              {...meal}
                            />
                          }
                          name={`${meal.workingTitle}${meal.version}`}
                          onChange={() => {
                            setSelectedItems((items) => {
                              const newItems = new Set(items)

                              if (newItems.has(index)) {
                                newItems.delete(index)
                              } else {
                                newItems.add(index)
                              }

                              return newItems
                            })
                          }}
                          value={index}
                        />
                      )
                    })}
                  </>
                ) : (
                  <>
                    {meals.map((meal) => {
                      return (
                        <MenuItem
                          key={`${meal.workingTitle}${meal.version}`}
                          {...meal}
                        />
                      )
                    })}
                  </>
                )}
              </div>

              <div className="mt-5">
                <ButtonLoading
                  disabled={term.meals.length > 0 && selectedItems.size === 0}
                  isLoading={isLoading}
                  onClick={() => createMeals(meals)}
                  size="large"
                >
                  {term.meals.length > 0
                    ? 'Create Selected Meals and Extras'
                    : 'Create Meals and Extras'}
                </ButtonLoading>
              </div>
            </>
          ) : (
            <div>
              <AlertInline alertStyle="success">
                Success! Meals and Extras have been created.{' '}
                <Link rel="noopener noreferrer" target="_blank" to="/meals">
                  <span className="underline">View Meals and Extras</span>
                </Link>
              </AlertInline>
            </div>
          )}
        </div>
      )}

      <MainDisplayOrder term={term} tpsotMeals={tpsotMeals} />
      <CustomizeIts term={term} tpsotMeals={tpsotMeals} />
    </div>
  )
}

export default ImportTermMeals

const MenuItem = ({
  facilityNetworks,
  productionCode,
  version,
  workingTitle,
}: {
  facilityNetworks: string[]
  productionCode: number
  version: string
  workingTitle: string
}) => {
  return (
    <div key={`${workingTitle}${version}`}>
      <span>
        WT: {workingTitle} (Production Code: {productionCode}
      </span>
      {facilityNetworks.length === 1 && <span> - {facilityNetworks}</span>})
    </div>
  )
}

const CustomizeIts = ({
  term,
  tpsotMeals,
}: {
  term: Term
  tpsotMeals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] } | undefined
}) => {
  const [isSaved, setIsSaved] = useState(false)
  const {
    mutate: createMenuSideSwaps,
    isLoading: isUpdatingMenuSideSwaps,
    error: createMenuSideSwapsError,
    isError: hasCreateMenuSideSwapsError,
  } = useCreateMenuSideSwaps()

  const termHasMealSwaps = term.subTerms.some((subTerm) => {
    return subTerm.defaultMenu?.mealSwaps.length > 0
  })

  if (!term || term.meals.length === 0 || !tpsotMeals) {
    return null
  }

  const sideSwaps = getMenuSideSwaps({ meals: tpsotMeals, term })

  const saveSideSwaps = () => {
    for (const [menuID, menuSideSwaps] of Object.entries(sideSwaps)) {
      createMenuSideSwaps(
        { data: menuSideSwaps, menuID },
        {
          onSuccess: () => {
            setIsSaved(true)
          },
        }
      )
    }
  }

  if (!sideSwaps || isEmpty(sideSwaps)) {
    return null
  }

  return (
    <div className="space-y-4">
      <h2 className="text-k/26_110">Customize Its</h2>
      {!isSaved ? (
        <div>
          {hasCreateMenuSideSwapsError && (
            <APIErrorDisplay
              error={createMenuSideSwapsError}
              errorCodeMessageMap={CREATE_MENU_SIDE_SWAPS_ERRORS}
            />
          )}
          <div className="flex space-x-4 items-center">
            <p>
              {termHasMealSwaps ? 'Update' : 'Save'} the Customize Its from
              TPSoT?
            </p>
            <ButtonLoading
              isLoading={isUpdatingMenuSideSwaps}
              onClick={() => {
                saveSideSwaps()
              }}
              size="medium"
            >
              {termHasMealSwaps ? 'Update' : 'Save'} Customize Its
            </ButtonLoading>
          </div>
        </div>
      ) : (
        <div>
          <AlertInline alertStyle="success">
            Success! Customize Its have been saved.{' '}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={`/terms/${term.id}/customize-it`}
            >
              <span className="underline">View Customize Its</span>
            </Link>
          </AlertInline>
        </div>
      )}
    </div>
  )
}

const MainDisplayOrder = ({
  term,
  tpsotMeals,
}: {
  term: Term
  tpsotMeals: { chicago: TPOSTMeal[]; slc: TPOSTMeal[] } | undefined
}) => {
  const [isSaved, setIsSaved] = useState(false)
  const {
    mutate: updateMenuMeals,
    isLoading: isUpdatingMenuMeals,
    error: updateMenuMealsError,
    isError: hasUpdateMenuMealsError,
  } = useUpdateMenuMeals()

  const termHasMainDisplayOrders = term.subTerms.some((subTerm) => {
    return subTerm.defaultMenu?.meals.some(
      (meal) => meal.mainDisplayOrder !== 0
    )
  })

  if (!term || term.meals.length === 0 || !tpsotMeals) {
    return null
  }

  const displayOrders = getMenuMainDisplayOrders({ meals: tpsotMeals, term })

  const saveMealDisplayOrders = () => {
    for (const [menuID, meals] of Object.entries(displayOrders)) {
      const updatedMeals = meals
        .map((meal, index) => {
          return {
            menuID,
            mealID: meal.id,
            mainDisplayOrder: index + 1,
            productionCode: meal.productionCode,
            expirationDate: meal.expirationDate,
          }
        })
        .filter((meal) => meal.mealID !== 0)

      updateMenuMeals(
        { data: updatedMeals, menuID },
        {
          onSuccess: () => {
            setIsSaved(true)
          },
        }
      )
    }
  }

  if (!displayOrders) {
    return null
  }

  return (
    <div className="space-y-4">
      <h2 className="text-k/26_110">Main Display Orders</h2>
      {!isSaved ? (
        <div>
          {hasUpdateMenuMealsError && (
            <APIErrorDisplay
              error={updateMenuMealsError}
              errorCodeMessageMap={UPDATE_MENU_MEALS_ERRORS}
            />
          )}
          <div className="flex space-x-4 items-center">
            <p>
              {termHasMainDisplayOrders ? 'Update' : 'Save'} the main display
              orders from TPSoT?
            </p>
            <ButtonLoading
              isLoading={isUpdatingMenuMeals}
              onClick={() => {
                saveMealDisplayOrders()
              }}
              size="medium"
            >
              {termHasMainDisplayOrders ? 'Update' : 'Save'} Display Orders
            </ButtonLoading>
          </div>
        </div>
      ) : (
        <div>
          <AlertInline alertStyle="success">
            Success! Main Display Orders have been saved.{' '}
            <Link
              rel="noopener noreferrer"
              target="_blank"
              to={`/terms/${term.id}/menu-display-orders/meals`}
            >
              <span className="underline">View Main Display Orders</span>
            </Link>
          </AlertInline>
        </div>
      )}
    </div>
  )
}
