import {
  DndContext,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  MealAdmin,
  MealSummary,
  Term,
  TermSubTerm,
} from '@tovala/browser-apis-combinedapi'
import {
  getMenuComponentsStandardized,
  makeMenuComponentsStandardized,
  MenuComponentsStandardized,
  MenuComponentStandardized,
  MenuComponentStandardizedBackgroundImageHeader,
  MenuComponentStandardizedMealWithExtra,
  MenuComponentStandardizedTextImageStack,
  MenuComponentStandardizedTextImageStackChildren,
} from '@tovala/browser-apis-menu-components'
import { Button, ButtonLoading } from '@tovala/component-library'
import { useMachine } from '@xstate/react'
import {
  compact,
  findIndex,
  flatMap,
  isEmpty,
  isEqual,
  orderBy,
} from 'lodash-es'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { v4 as uuidV4 } from 'uuid'

import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from 'components/common/TabGroup'

import {
  MenuComponents,
  MenuVariants,
  uploadImage,
  useCreateMenuVariants,
  useDefaultMenus,
  useSetDefaultMenu,
  useTermMenuVariants,
  useUpdateMenuVariant,
} from '@tovala/browser-apis-menu-delivery'
import ToggleButton from 'components/common/ToggleButton'
import { useToast } from 'contexts/toast'
import ActiveComponentDragOverlay from './ComponentDragOverlay'
import BackgroundImageHeaderDialog from './dialogs/BackgroundImageHeaderDialog'
import CopyComponentDialog from './dialogs/CopyComponentDialog'
import DeleteMenusDialog from './dialogs/DeleteMenusDialog'
import EditAdditionalMealWithExtraDialog from './dialogs/EditAdditionalMealWithExtraDialog'
import MealWithExtraDialog from './dialogs/MealWithExtraDialog'
import TextImageStackDialog from './dialogs/TextImageStackDialog'
import DraggableNewComponent, {
  DraggableNewComponentProps,
} from './DraggableNewComponent'
import { type MenusState, useMenuEditor } from './hooks'
import BackgroundImageHeaderButton from './menuComponents/BackgroundImageHeaderButton'
import TextImageStackButton from './menuComponents/TextImageStackButton'
import MenuComponentsGrid from './MenuComponentsGrid'
import { placeholderMachine } from './placeholderMachine'
import {
  ComponentDropPlaceholder,
  getMealImageURL,
  isMealCarouselComponent,
  isMealComponent,
  isMealWithExtraComponent,
  isTwoMealPickerComponent,
  ViewType,
} from './utils'
import { downloadJSON, makeCDNComponents } from './utils-cdn'
import { makeMDSComponents } from './utils-mds'
import { getTPSOTMenus, TPSOTMenus } from 'utils/parseTPSOT'
import UpdateMenusDialog, {
  type DialogData as UpdateMenusDialogData,
} from './dialogs/UpdateMenusDialog'
import { getUserId } from 'utils/getUserId'
import { produce } from 'immer'
import TPSOTImportDialog from './dialogs/TPSOTImportDialog'
import { getEnvVar } from 'utils/env'

const FACILITY_NETWORKS = {
  chicago: 'Chicago',
  slc: 'SLC',
}

const MenuEditor = ({
  orderedSubTerms,
  term,
  termMealSummaries,
}: {
  orderedSubTerms: TermSubTerm[]
  term: Term
  termMealSummaries: MealSummary[][]
}) => {
  const termDefaultMenusResponse = useDefaultMenus({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
    retry: false,
    refetchOnWindowFocus: false,
  })
  const termDefaultMenus = termDefaultMenusResponse
    .map((res) => res.data)
    .filter((defaultMenu): defaultMenu is MenuComponents => !!defaultMenu)
  const isLoadingDefaultMenus = termDefaultMenusResponse.some((res) => {
    return res.isLoading
  })

  const termMenuVariantsResponse = useTermMenuVariants({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
    retry: false,
    refetchOnWindowFocus: false,
  })
  const termMenuVariants = termMenuVariantsResponse
    .map((res) => res.data)
    .filter((menuVariants): menuVariants is MenuVariants => !!menuVariants)
  const isLoadingMenuVariants = termMenuVariantsResponse.some((res) => {
    return res.isLoading
  })

  const initialMenus: MenusState = {}

  if (!isLoadingDefaultMenus && !isLoadingMenuVariants) {
    orderedSubTerms.forEach((subTerm, index) => {
      const mealSummaries = termMealSummaries[index]

      const variantsForSubTerm = termMenuVariants.find(
        (variant) => variant.subTermID === subTerm.id
      )

      if (variantsForSubTerm) {
        variantsForSubTerm.variants.forEach((variant, index) => {
          // The last variant is always the "Core" variant
          // Will be updated to variant without userAspect when backend is updated
          const isCoreVariant = index === variantsForSubTerm.variants.length - 1

          let description = variant.description
          if (!description) {
            // TODO update this to use the userAspect
            description = `${FACILITY_NETWORKS[subTerm.facilityNetwork]} ${
              subTerm.shipPeriod
            } - ${isCoreVariant ? 'Core' : 'Thrive'}`
          }

          const components = getMenuComponentsStandardized({
            meals: mealSummaries,
            menuComponents: variant.components,
            suggestions: undefined,
          })

          initialMenus[variant.variantID] = {
            components,
            description,
            id: variant.variantID,
            isDefault: isCoreVariant,
            serverComponents: components,
            subTermID: variant.subTermID,
          }
        })
      } else {
        const defaultMenu = termDefaultMenus.find(
          (menu) => menu.subTermID === subTerm.id
        )

        const components = defaultMenu
          ? getMenuComponentsStandardized({
              meals: mealSummaries,
              menuComponents: defaultMenu.components,
              suggestions: undefined,
            })
          : makeMenuComponentsStandardized({
              meals: orderBy(mealSummaries, 'mainDisplayOrder'),
              mealSwaps: subTerm.defaultMenu.mealSwaps,
              suggestions: undefined,
              specialEvent: subTerm.specialEvent || term.special_event,
            })

        initialMenus[subTerm.id] = {
          components,
          description: `${FACILITY_NETWORKS[subTerm.facilityNetwork]} ${
            subTerm.shipPeriod
          } - Core`,
          id: subTerm.id,
          isDefault: true,
          serverComponents: defaultMenu ? components : [],
          subTermID: subTerm.id,
        }
      }
    })
  }

  return !isEmpty(initialMenus) ? (
    <Menus
      initialMenus={initialMenus}
      orderedSubTerms={orderedSubTerms}
      term={term}
      termMealSummaries={termMealSummaries}
    />
  ) : null
}

export default MenuEditor

const Menus = ({
  initialMenus,
  orderedSubTerms,
  term,
  termMealSummaries,
}: {
  initialMenus: MenusState
  orderedSubTerms: TermSubTerm[]
  term: Term
  termMealSummaries: MealSummary[][]
}) => {
  const { openToast } = useToast()

  const [dragOverlayAddComponentType, setDragOverlayAddComponentType] =
    useState<DraggableNewComponentProps['componentType'] | null>(null)
  const [menuCSVData, setMenuCSVData] = useState<TPSOTMenus | null>(null)
  const [placeholderState, placeholderSend] = useMachine(placeholderMachine)
  const onClearPlaceholder = useCallback(() => {
    placeholderSend({ type: 'clearPlaceholder' })
  }, [placeholderSend])
  const onInsertPlaceholder = useCallback(
    ({
      componentType,
      index,
    }: {
      componentType: DraggableNewComponentProps['componentType']
      index: number
    }) => {
      placeholderSend({ componentType, index, type: 'setPlaceholder' })
    },
    [placeholderSend]
  )
  const { placeholderComponentType, placeholderIndex } =
    placeholderState.context

  const {
    addComponent,
    addMenu,
    deleteMenu,
    makeMenuComponents,
    menus,
    onChangeSelectedMenu,
    removeComponent,
    reorderComponents,
    replaceAllMenus,
    selectedMenu,
    updateComponent,
    updateMenu,
  } = useMenuEditor({
    orderedSubTerms,
    initialMenus,
    termMealSummaries,
  })

  let selectedComponents: (
    | ComponentDropPlaceholder
    | MenuComponentStandardized
  )[] = selectedMenu?.components || []
  if (placeholderIndex !== null) {
    selectedComponents = [
      ...selectedComponents.slice(0, placeholderIndex),
      {
        id: uuidV4(),
        type: 'dropPlaceholder',
        properties: { componentType: placeholderComponentType },
      },
      ...selectedComponents.slice(placeholderIndex),
    ]
  }

  const { mutate: updateMenuVariant, isLoading: isSavingMenuVariant } =
    useUpdateMenuVariant()
  const { mutate: createMenuVariants } = useCreateMenuVariants({
    onSuccess: (...args) => {
      const data = args[0]

      const subTermMenuData = menuCSVData && menuCSVData[data.subTermID]

      data.variants.forEach((variant, index) => {
        // The last variant is always the "Core" variant
        const isCoreVariant = index === data.variants.length - 1

        // Delete initial default menu from state for subTerms that have variants
        deleteMenu({ menuID: data.subTermID })

        if (subTermMenuData && 'variants' in subTermMenuData) {
          const { mealIDs, mealSwaps } = subTermMenuData.variants[index]

          const components = makeMenuComponents({
            mealIDs,
            mealSwaps,
            subTermID: data.subTermID,
          })

          const mdsComponents = makeMDSComponents({
            components,
          })

          updateMenuVariant({
            subTermID: data.subTermID,
            variantID: variant.variantID,
            data: mdsComponents,
          })

          if (isCoreVariant) {
            setDefaultMenu({
              subTermID: data.subTermID,
              data: mdsComponents,
            })
          }

          addMenu({
            menu: {
              description:
                variant.description ||
                subTermMenuData.variants[index].description,
              id: variant.variantID,
              isDefault: isCoreVariant,
              components,
              serverComponents: components,
              subTermID: data.subTermID,
            },
            menuID: variant.variantID,
          })
        }
      })
    },
  })

  const { mutate: setDefaultMenu, isLoading: isSavingDefaultMenu } =
    useSetDefaultMenu()

  const [isUploadingImage, setIsUploadingImage] = useState(false)
  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(selectedComponents, (component) => component.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1
  const activeComponent = selectedComponents[activeIndex]

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  function onClickDownload() {
    orderedSubTerms.forEach((subTerm) => {
      const defaultMenu = menus[subTerm.id]
        ? menus[subTerm.id]
        : Object.values(menus).find((menu) => {
            return menu.subTermID === subTerm.id && menu.isDefault
          })

      if (!defaultMenu) {
        return
      }

      downloadJSON({
        filename: `components_${subTerm.id}.json`,
        json: {
          termID: subTerm.termID,
          subTermID: subTerm.id,
          components: makeCDNComponents(defaultMenu.components),
        },
      })
    })
  }

  const [dialog, setDialog] = useState<
    | {
        componentType: DraggableNewComponentProps['componentType']
        data?: {
          component?: MenuComponentsStandardized[number]
          index?: number
        }
        type: 'componentEdit'
      }
    | {
        data: { component: MenuComponentsStandardized[number]; index: number }
        type: 'componentCopy'
      }
    | {
        data: {
          menus: Record<
            string,
            {
              components: MenuComponentStandardizedMealWithExtra[]
              description: string
              id: string
            }
          >
          meal: MealAdmin
        }
        type: 'additionalMealWithExtraEdit'
      }
    | {
        data: {
          menus: MenusState
          subTermIDs: string[]
          termID: number
        }
        type: 'deleteMenus'
      }
    | {
        data: UpdateMenusDialogData
        type: 'createMenus'
      }
    | {
        data: UpdateMenusDialogData
        type: 'overwriteMenus'
      }
    | {
        type: 'tpsotImport'
      }
    | {
        type: 'tpsotReimport'
      }
    | null
  >(null)
  const [viewType, setViewType] = useState<ViewType>('desktop')

  const componentToEdit =
    dialog?.type === 'componentEdit' ? dialog.data?.component : undefined

  const handleTPSOTImport = async (files: File[]) => {
    try {
      const tpsotMenus = await getTPSOTMenus({
        file: files[0],
        orderedSubTerms,
      })

      setMenuCSVData(tpsotMenus)

      setDialog({
        data: {
          menus: Object.values(tpsotMenus).flatMap((menu) => {
            if ('variants' in menu) {
              return menu.variants.map((variant) => {
                return {
                  description: variant.description,
                  errors: variant.errors,
                  mealIDs: variant.mealIDs,
                }
              })
            }

            return {
              description: menu.description,
              errors: menu.errors,
              mealIDs: menu.mealIDs,
            }
          }),
          onSubmit: () => {
            orderedSubTerms.forEach((subTerm) => {
              const subTermMenu = tpsotMenus[subTerm.id]

              if ('variants' in subTermMenu) {
                const partitionMethod = subTermMenu.variants.some(
                  (variant) => variant.userAspects !== null
                )
                  ? 'UserAspectMatching'
                  : 'ModuloUserID'

                const userAspectParams =
                  partitionMethod === 'ModuloUserID'
                    ? null
                    : [
                        {
                          userAspects: ['thrive' as const],
                        },
                      ]
                const startAt = new Date()
                startAt.setMinutes(startAt.getMinutes() + 1)

                createMenuVariants({
                  data: {
                    description: `term ${subTerm.termID} ${subTerm.facilityNetwork} ${subTerm.shipPeriod}`,
                    partitionMethod,
                    owner: `${getUserId()}`,
                    numberOfVariants: subTermMenu.variants.length.toString(),
                    startAt: startAt.toISOString(),
                    endAt: term.order_by,
                    userAspectParams,
                  },
                  subTermID: subTermMenu.subTermID,
                })
              } else {
                const components = makeMenuComponents({
                  mealIDs: subTermMenu.mealIDs,
                  mealSwaps: subTermMenu.mealSwaps,
                  subTermID: subTermMenu.subTermID,
                })

                const mdsComponents = makeMDSComponents({
                  components,
                })

                setDefaultMenu({
                  subTermID: subTerm.id,
                  data: mdsComponents,
                })

                addMenu({
                  menu: {
                    description: subTermMenu.description,
                    id: subTermMenu.subTermID,
                    isDefault: true,
                    components,
                    serverComponents: components,
                    subTermID: subTermMenu.subTermID,
                  },

                  menuID: subTermMenu.subTermID,
                })
              }
            })

            setDialog(null)
          },
          updateType: 'create',
        },
        type: 'createMenus',
      })
    } catch (error) {
      // TODO handle error
      console.log(error)
    }
  }

  const handleTPSOTReimport = async (files: File[]) => {
    try {
      const tpsotMenus = await getTPSOTMenus({
        file: files[0],
        orderedSubTerms,
      })

      setDialog({
        data: {
          menus: Object.values(menus).flatMap((menu) => {
            const tpsotSubterm = tpsotMenus[menu.subTermID]

            if ('variants' in tpsotSubterm) {
              const tpsotMenu = tpsotSubterm.variants.find((variant) => {
                return variant.description === menu.description
              })

              return {
                description: menu.description,
                errors: tpsotMenu?.errors ?? ['Menu not found in TPSoT'],
                mealIDs: tpsotMenu?.mealIDs ?? [],
              }
            }

            const tpsotMenu =
              tpsotSubterm.description === menu.description
                ? tpsotSubterm
                : null

            return {
              description: menu.description,
              errors: tpsotMenu?.errors ?? ['Menu not found in TPSoT'],
              mealIDs: tpsotMenu?.mealIDs ?? [],
            }
          }),
          onSubmit: () => {
            const updatedMenus = produce(menus, (draft) => {
              Object.values(menus).forEach((menu) => {
                const tpsotSubterm = tpsotMenus[menu.subTermID]

                if ('variants' in tpsotSubterm) {
                  const tpsotVariant = tpsotSubterm.variants.find((variant) => {
                    return variant.description === menu.description
                  })

                  if (tpsotVariant) {
                    const components = makeMenuComponents({
                      mealIDs: tpsotVariant.mealIDs,
                      mealSwaps: tpsotVariant.mealSwaps,
                      subTermID: tpsotSubterm.subTermID,
                    })

                    draft[menu.id].components = components
                  }
                } else {
                  const components = makeMenuComponents({
                    mealIDs: tpsotSubterm.mealIDs,
                    mealSwaps: tpsotSubterm.mealSwaps,
                    subTermID: tpsotSubterm.subTermID,
                  })

                  draft[menu.id].components = components
                }
              })
            })

            replaceAllMenus({ menus: updatedMenus })

            handleSaveAllMenus(updatedMenus)

            setDialog(null)
          },
          updateType: 'update',
        },
        type: 'overwriteMenus',
      })
    } catch (error) {
      // TODO handle error
      console.log(error)
    }
  }

  const handleSaveAllMenus = (menus: MenusState) => {
    Object.values(menus).forEach((menu) => {
      const isModified = !isEqual(menu.components, menu.serverComponents)

      if (isModified) {
        const mdsComponents = makeMDSComponents({
          components: menu.components,
        })

        const isDefaultMenu = menu.isDefault
        const isMenuVariant = menu.id !== menu.subTermID

        if (isDefaultMenu) {
          setDefaultMenu({
            subTermID: menu.subTermID,
            data: mdsComponents,
          })
        }

        if (isMenuVariant) {
          updateMenuVariant({
            data: mdsComponents,
            subTermID: menu.subTermID,
            variantID: menu.id,
          })
        }

        updateMenu({
          menu: {
            serverComponents: menu.components,
          },
          menuID: menu.id,
        })
      }
    })
  }

  const noMenusSaved = Object.values(initialMenus)
    .map((menu) => menu.serverComponents)
    .every((components) => components.length === 0)

  useEffect(() => {
    if (noMenusSaved) {
      setDialog({
        type: 'tpsotImport',
      })
    }
  }, [noMenusSaved])

  return (
    <DndContext
      onDragEnd={({ active }) => {
        setActiveID(null)
        setDragOverlayAddComponentType(null)

        if (active.data.current?.type === 'newComponent') {
          // TODO update type definition
          setDialog({
            componentType: active.data.current?.componentType,
            data: {
              index: placeholderIndex || 0,
            },
            type: 'componentEdit',
          })
        }
      }}
      onDragOver={({ active, over }) => {
        if (!over) {
          return
        }

        const overIndex = getIndex(over.id)

        if (active.data.current?.type === 'newComponent') {
          if (overIndex !== -1) {
            onInsertPlaceholder({
              componentType: active.data.current.componentType,
              index: overIndex,
            })
          }
        } else {
          if (activeIndex !== overIndex && selectedMenu) {
            reorderComponents(activeIndex, overIndex, selectedMenu.id)
          }
        }
      }}
      onDragStart={({ active }) => {
        if (!active) {
          return
        }

        setActiveID(active.id)

        if (active.data.current?.type === 'newComponent') {
          setDragOverlayAddComponentType(active.data.current?.componentType)
        }
      }}
      sensors={sensors}
    >
      {selectedMenu ? (
        <>
          <TabGroup
            onChange={(index) => {
              const menuID = Object.values(menus)[index]?.id
              if (menuID) {
                onChangeSelectedMenu(menuID)
              }
            }}
            selectedIndex={
              selectedMenu
                ? Object.values(menus).findIndex(
                    (menu) => menu.id === selectedMenu.id
                  )
                : 0
            }
          >
            <div className="inset-0 overflow-hidden fixed bg-grey-0 pt-[56px] font-sans-new">
              <div className="grid h-full grid-cols-[350px_auto] divide-x divide-grey-3">
                <div className="pr-16 pl-4 py-8 space-y-8 overflow-auto">
                  <div className="space-y-4">
                    <h1 className="text-k/28_130">Term #{term.id} Menus</h1>

                    <ButtonLoading
                      buttonStyle="dark"
                      disabled={Object.values(menus).every((menu) =>
                        isEqual(menu.components, menu.serverComponents)
                      )}
                      isLoading={isSavingDefaultMenu}
                      onClick={() => {
                        handleSaveAllMenus(menus)
                      }}
                      size="medium"
                    >
                      Save All Menus
                    </ButtonLoading>

                    <TabList tabStyle="cards">
                      {Object.values(menus).map((menu) => {
                        const hasSavedMenu = menu.serverComponents.length > 0
                        const isModified = !isEqual(
                          menu.components,
                          menu.serverComponents
                        )
                        return (
                          <div key={menu.id}>
                            <Tab tabStyle="cards">
                              <div className="text-k/20_110">
                                {menu.description}
                              </div>
                              <div className="text-body-sm text-grey-9">
                                {!hasSavedMenu
                                  ? 'No menu saved'
                                  : isModified
                                  ? 'Unsaved changes'
                                  : 'Saved'}
                              </div>
                            </Tab>
                          </div>
                        )
                      })}
                    </TabList>

                    {noMenusSaved && dialog === null && (
                      <div>
                        <Button
                          buttonStyle="gray"
                          onClick={() => {
                            setDialog({
                              type: 'tpsotImport',
                            })
                          }}
                          size="small"
                        >
                          Import TPSoT
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="space-y-2">
                    <h2 className="text-k/28_130">Components</h2>
                    <p className="text-k/14_120 text-grey-9">
                      Drag a component into the grid to add it to a menu.
                    </p>
                  </div>

                  <div className="align-center flex flex-wrap">
                    <DraggableNewComponent componentType="backgroundImageHeader">
                      <BackgroundImageHeaderButton />
                    </DraggableNewComponent>

                    <DraggableNewComponent componentType="textImageStack">
                      <TextImageStackButton />
                    </DraggableNewComponent>
                  </div>

                  <div className="space-y-2">
                    <h2 className="text-k/28_130">Preview</h2>
                    <div className="flex">
                      <ToggleButton
                        isFirst
                        isSelected={viewType === 'desktop'}
                        onClick={() => {
                          setViewType('desktop')
                        }}
                      >
                        Desktop
                      </ToggleButton>
                      <ToggleButton
                        isSelected={viewType === 'mobile'}
                        onClick={() => {
                          setViewType('mobile')
                        }}
                      >
                        Mobile
                      </ToggleButton>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <h2 className="text-k/28_130">Download Files</h2>
                    <p className="text-k/14_120 text-grey-9">
                      Download JSON files for Core menus.
                    </p>
                    <Button
                      onClick={() => {
                        onClickDownload()
                      }}
                      size="medium"
                    >
                      Download Menu Components
                    </Button>
                  </div>

                  {!noMenusSaved &&
                    (getEnvVar('APP_ENV') === 'development' ||
                      !term.ready_for_view) && (
                      <div className="space-y-2">
                        <h2 className="text-k/28_130">Reset Menus</h2>
                        <div className="space-y-8">
                          <div className="space-y-2">
                            <p className="text-k/14_120 text-grey-9">
                              Need to start over with a fresh TPSoT import?
                            </p>
                            <Button
                              buttonStyle="gray"
                              onClick={() => {
                                setDialog({
                                  type: 'tpsotReimport',
                                })
                              }}
                              size="medium"
                            >
                              Import TPSoT
                            </Button>
                          </div>

                          <div className="space-y-2">
                            <p className="text-k/14_120 text-grey-9">
                              Need to completely start over, including deleting
                              all menu variants?
                            </p>
                            <Button
                              buttonStyle="gray"
                              onClick={() => {
                                setDialog({
                                  data: {
                                    menus,
                                    subTermIDs: orderedSubTerms.map(
                                      (subTerm) => subTerm.id
                                    ),
                                    termID: term.id,
                                  },
                                  type: 'deleteMenus',
                                })
                              }}
                              size="medium"
                            >
                              Delete All Menus
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                <div className="flex flex-col space-y-8 pl-16">
                  <div className="h-px grow overflow-auto pb-24">
                    <TabPanels as={Fragment}>
                      {Object.values(menus).map((menu) => {
                        const isMenuVariant = menu.id !== menu.subTermID

                        return (
                          <TabPanel key={menu.id}>
                            <div className="flex items-center justify-between sticky top-0 z-20 bg-grey-0 py-4 -mt-4">
                              <h2 className="text-k/28_130">
                                {menu.description}
                              </h2>

                              <div className="flex space-x-4">
                                <ButtonLoading
                                  buttonStyle="dark"
                                  disabled={isEqual(
                                    menu.components,
                                    menu.serverComponents
                                  )}
                                  isLoading={
                                    isMenuVariant
                                      ? isSavingMenuVariant
                                      : isSavingDefaultMenu
                                  }
                                  onClick={() => {
                                    const mdsComponents = makeMDSComponents({
                                      components: menu.components,
                                    })

                                    if (isMenuVariant) {
                                      updateMenuVariant(
                                        {
                                          data: mdsComponents,
                                          subTermID: menu.subTermID,
                                          variantID: menu.id,
                                        },
                                        {
                                          onSuccess: () => {
                                            openToast({
                                              heading: 'Menu Saved',
                                              message: `Success! The menu for ${menu.description} has been saved.`,
                                              type: 'success',
                                            })

                                            updateMenu({
                                              menu: {
                                                serverComponents:
                                                  menu.components,
                                              },
                                              menuID: menu.id,
                                            })
                                          },
                                        }
                                      )

                                      if (menu.isDefault) {
                                        setDefaultMenu({
                                          data: mdsComponents,
                                          subTermID: menu.subTermID,
                                        })
                                      }
                                    } else {
                                      setDefaultMenu(
                                        {
                                          data: mdsComponents,
                                          subTermID: menu.subTermID,
                                        },
                                        {
                                          onSuccess: () => {
                                            openToast({
                                              heading: 'Menu Saved',
                                              message: `Success! The menu for ${menu.description} has been saved.`,
                                              type: 'success',
                                            })

                                            updateMenu({
                                              menu: {
                                                serverComponents:
                                                  menu.components,
                                              },
                                              menuID: menu.id,
                                            })
                                          },
                                        }
                                      )
                                    }
                                  }}
                                  size="large"
                                >
                                  Save Menu
                                </ButtonLoading>
                              </div>
                            </div>
                            <div>
                              <MenuComponentsGrid
                                activeID={activeID}
                                components={selectedComponents}
                                onClickCopy={(data) => {
                                  setDialog({ data, type: 'componentCopy' })
                                }}
                                onClickDelete={(
                                  component: MenuComponentStandardized
                                ) => {
                                  removeComponent({
                                    component,
                                    menuID: selectedMenu.id,
                                  })
                                }}
                                onClickEdit={(opts) => {
                                  setDialog({
                                    ...opts,
                                    type: 'componentEdit',
                                  })
                                }}
                                onReorderComponents={(
                                  currentIndex: number,
                                  newIndex: number
                                ) => {
                                  reorderComponents(
                                    currentIndex,
                                    newIndex,
                                    selectedMenu.id
                                  )
                                }}
                                viewType={viewType}
                              />
                            </div>
                          </TabPanel>
                        )
                      })}
                    </TabPanels>
                  </div>
                </div>
              </div>
            </div>
          </TabGroup>

          {dialog?.type === 'componentEdit' ? (
            <>
              {dialog.componentType === 'backgroundImageHeader' ? (
                <BackgroundImageHeaderDialog
                  initialValues={
                    componentToEdit?.type === 'backgroundImageHeader'
                      ? {
                          image: {
                            contentType: undefined,
                            file: undefined,
                            filename: undefined,
                            presignedURL: undefined,
                            src: componentToEdit.properties.image.url,
                            url: componentToEdit.properties.image.url,
                          },
                          subtitle: componentToEdit.properties.subtitle,
                          subtitleColor:
                            componentToEdit.properties.subtitleColor ?? '',
                          title: componentToEdit.properties.title,
                          titleColor:
                            componentToEdit.properties.titleColor ?? '',
                        }
                      : undefined
                  }
                  isUploadingImage={isUploadingImage}
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={async (data) => {
                    let hasUploadedImage = !!(
                      componentToEdit?.type === 'backgroundImageHeader' &&
                      componentToEdit.properties.image.url
                    )

                    if (
                      data.image.presignedURL &&
                      data.image.file &&
                      data.image.contentType
                    ) {
                      setIsUploadingImage(true)

                      try {
                        const res = await uploadImage({
                          contentType: data.image.contentType,
                          data: data.image.file,
                          presignedURL: data.image.presignedURL,
                        })

                        if (res) {
                          hasUploadedImage = true
                        }
                      } catch (error) {
                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })
                      } finally {
                        setIsUploadingImage(false)
                      }
                    }

                    if (hasUploadedImage && data.image.url) {
                      const component: MenuComponentStandardizedBackgroundImageHeader =
                        {
                          id: uuidV4(),
                          properties: {
                            image: {
                              url: data.image.url,
                            },
                            subtitle: data.subtitle,
                            subtitleColor: data.subtitleColor,
                            title: data.title,
                            titleColor: data.titleColor,
                          },
                          type: 'backgroundImageHeader',
                        }

                      if (dialog.data?.component) {
                        updateComponent({
                          component: {
                            ...component,
                            id: dialog.data.component.id,
                          },
                          menuID: selectedMenu.id,
                        })
                      } else {
                        addComponent({
                          component,
                          index: dialog.data?.index ?? 0,
                          menuID: selectedMenu.id,
                        })
                      }

                      onClearPlaceholder()
                      setDialog(null)
                    } else {
                      openToast({
                        heading: 'Missing image data',
                        message: 'Please check the image file and try again.',
                        type: 'error',
                      })
                    }
                  }}
                />
              ) : dialog.componentType === 'textImageStack' ? (
                <TextImageStackDialog
                  components={selectedComponents}
                  initialValues={
                    componentToEdit?.type === 'textImageStack'
                      ? {
                          associatedComponentIDs: new Set(
                            componentToEdit.properties.children.map(
                              ({ id }) => id
                            )
                          ),
                          image: componentToEdit.properties.image
                            ? {
                                contentType: undefined,
                                file: undefined,
                                filename: undefined,
                                presignedURL: undefined,
                                src: componentToEdit.properties.image.url,
                                url: componentToEdit.properties.image.url,
                              }
                            : undefined,
                          subtitle: componentToEdit.properties.subtitle,
                          title: componentToEdit.properties.title,
                        }
                      : { associatedComponentIDs: new Set(), title: '' }
                  }
                  isUploadingImage={isUploadingImage}
                  onClose={() => {
                    onClearPlaceholder()
                    setDialog(null)
                  }}
                  onSave={async (data) => {
                    let isImageUploadRequired = false
                    if (data.image && data.image.presignedURL) {
                      isImageUploadRequired = true

                      if (!data.image.contentType || !data.image.file) {
                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })

                        return
                      }

                      setIsUploadingImage(true)

                      try {
                        const res = await uploadImage({
                          contentType: data.image.contentType,
                          data: data.image.file,
                          presignedURL: data.image.presignedURL,
                        })

                        if (res) {
                          isImageUploadRequired = false
                        }
                      } catch (error) {
                        isImageUploadRequired = true

                        openToast({
                          heading: 'Unable to upload image',
                          message: 'Please check the image file and try again.',
                          type: 'error',
                        })
                      } finally {
                        setIsUploadingImage(false)
                      }
                    }

                    if (!isImageUploadRequired) {
                      let previousChildren: MenuComponentStandardizedTextImageStackChildren =
                        []

                      if (
                        dialog.data?.component &&
                        dialog.data.component.type === 'textImageStack'
                      ) {
                        previousChildren = [
                          ...dialog.data.component.properties.children,
                        ]
                      }

                      const component: MenuComponentStandardizedTextImageStack =
                        {
                          id: dialog.data?.component?.id ?? uuidV4(),
                          properties: {
                            children: compact(
                              Array.from(data.associatedComponentIDs).map(
                                (componentID) => {
                                  const component = [
                                    ...previousChildren,
                                    ...selectedComponents,
                                  ].find(
                                    (component) => component.id === componentID
                                  )

                                  if (
                                    isMealComponent(component) ||
                                    isTwoMealPickerComponent(component) ||
                                    isMealCarouselComponent(component) ||
                                    isMealWithExtraComponent(component)
                                  ) {
                                    return component
                                  }
                                }
                              )
                            ),
                            image: data.image
                              ? {
                                  url: data.image.url,
                                }
                              : undefined,
                            subtitle: data.subtitle,
                            title: data.title,
                          },
                          type: 'textImageStack',
                        }

                      if (
                        dialog.data?.component &&
                        dialog.data.component.type === 'textImageStack'
                      ) {
                        updateComponent({
                          component,
                          previousChildren:
                            dialog.data.component.properties.children,
                          menuID: selectedMenu.id,
                        })
                      } else {
                        addComponent({
                          component,
                          index: dialog.data?.index ?? 0,
                          menuID: selectedMenu.id,
                        })
                      }

                      onClearPlaceholder()
                      setDialog(null)
                    } else {
                      openToast({
                        heading: 'Missing image data',
                        message: 'Please check the image file and try again.',
                        type: 'error',
                      })
                    }
                  }}
                />
              ) : dialog.componentType === 'mealWithExtra' ? (
                <MealWithExtraDialog
                  initialValues={
                    componentToEdit?.type === 'mealWithExtra'
                      ? {
                          detailsMealID:
                            componentToEdit.properties.mealOption.detailsMealID,
                        }
                      : undefined
                  }
                  onClose={() => {
                    setDialog(null)
                  }}
                  onSave={(data, meal) => {
                    if (
                      dialog.data?.component &&
                      dialog.data.component.type === 'mealWithExtra' &&
                      meal
                    ) {
                      const componentID = dialog.data.component.id

                      const url = getMealImageURL(meal)

                      const component: MenuComponentStandardizedMealWithExtra =
                        {
                          ...dialog.data.component,
                          properties: {
                            ...dialog.data.component.properties,
                            mealOption: {
                              ...dialog.data.component.properties.mealOption,
                              detailsMealID: data.detailsMealID,
                              meal: {
                                ...dialog.data.component.properties.mealOption
                                  .meal,
                                image: {
                                  url,
                                },
                              },
                            },
                          },
                        }

                      updateComponent({
                        component,
                        menuID: selectedMenu.id,
                      })

                      // Find other mealWithExtra components that have the same extra and
                      // haven't already been updated with the termless meal ID that was selected
                      // so we can prompt the user to update the other matching extras
                      const componentsWithMatchingExtra: Record<
                        string,
                        {
                          components: MenuComponentStandardizedMealWithExtra[]
                          description: string
                          id: string
                        }
                      > = {}

                      if (meal) {
                        Object.values(menus).forEach((menu) => {
                          const matchingComponents = menu.components
                            .filter((c) => {
                              if (
                                c.type === 'mealWithExtra' &&
                                c.id !== componentID &&
                                c.properties.mealOption.detailsMealID !==
                                  meal.id &&
                                c.properties.mealOption.meal.mealSummary.shortSubtitle
                                  .toLowerCase()
                                  .trim() ===
                                  component.properties.mealOption.meal.mealSummary.shortSubtitle
                                    .toLowerCase()
                                    .trim()
                              ) {
                                return c
                              }
                            })
                            .filter(
                              (
                                component
                              ): component is MenuComponentStandardizedMealWithExtra =>
                                !!component
                            )

                          if (matchingComponents.length > 0) {
                            componentsWithMatchingExtra[menu.id] = {
                              components: matchingComponents,
                              description: menu.description,
                              id: menu.id,
                            }
                          }
                        })
                      }

                      if (
                        !isEmpty(componentsWithMatchingExtra) &&
                        flatMap(
                          componentsWithMatchingExtra,
                          (components) => components
                        ).length > 0
                      ) {
                        setDialog({
                          data: {
                            menus: componentsWithMatchingExtra,
                            meal,
                          },
                          type: 'additionalMealWithExtraEdit',
                        })
                      } else {
                        setDialog(null)
                      }
                    }
                  }}
                />
              ) : null}
            </>
          ) : dialog?.type === 'componentCopy' ? (
            <>
              {dialog.data.component.type === 'textImageStack' ? (
                <CopyComponentDialog
                  menus={Object.values(menus)
                    .map((menu) => ({
                      id: menu.id,
                      description: menu.description,
                    }))
                    .filter((menu) => menu.id !== selectedMenu?.id)}
                  onClose={() => {
                    setDialog(null)
                  }}
                  onSave={(menuIDs) => {
                    menuIDs.forEach((menuID) => {
                      if (dialog.data.component.type === 'textImageStack') {
                        const menuChildren: MenuComponentStandardizedTextImageStackChildren =
                          []
                        dialog.data.component.properties.children.forEach(
                          (child) => {
                            const menuChild = menus[menuID].components.find(
                              (component) => {
                                if (
                                  child.type === 'meal' &&
                                  component.type === 'meal'
                                ) {
                                  return (
                                    component.properties.id ===
                                    child.properties.id
                                  )
                                } else if (
                                  child.type === 'animatedMealCarousel' &&
                                  component.type === 'animatedMealCarousel'
                                ) {
                                  return (
                                    component.properties.mealOptions[0].id ===
                                    child.properties.mealOptions[0].id
                                  )
                                } else if (
                                  child.type === 'mealWithExtra' &&
                                  component.type === 'mealWithExtra'
                                ) {
                                  return (
                                    component.properties.meal.id ===
                                    child.properties.meal.id
                                  )
                                }
                              }
                            )

                            if (
                              menuChild &&
                              (menuChild.type === 'meal' ||
                                menuChild.type === 'animatedMealCarousel' ||
                                menuChild.type === 'mealWithExtra')
                            ) {
                              menuChildren.push(menuChild)
                            }
                          }
                        )

                        const menuComponent = produce(
                          dialog.data.component,
                          (draft) => {
                            draft.properties.children = menuChildren
                            draft.id = uuidV4()
                          }
                        )

                        addComponent({
                          component: menuComponent,
                          index: dialog.data.index,
                          menuID,
                        })
                      }
                    })
                    setDialog(null)
                  }}
                />
              ) : (
                <CopyComponentDialog
                  menus={Object.values(menus)
                    .map((menu) => ({
                      id: menu.id,
                      description: menu.description,
                    }))
                    .filter((menu) => menu.id !== selectedMenu?.id)}
                  onClose={() => {
                    setDialog(null)
                  }}
                  onSave={(menuIDs) => {
                    menuIDs.forEach((menuID) => {
                      addComponent({
                        component: { ...dialog.data.component, id: uuidV4() },
                        index: dialog.data.index,
                        menuID,
                      })
                    })
                    setDialog(null)
                  }}
                />
              )}
            </>
          ) : dialog?.type === 'additionalMealWithExtraEdit' ? (
            <EditAdditionalMealWithExtraDialog
              data={dialog.data}
              onClose={() => {
                setDialog(null)
              }}
              onSave={(menusToUpdate) => {
                const url = getMealImageURL(dialog.data.meal)

                Object.values(menusToUpdate).forEach((menuToUpdate) => {
                  const updatedComponents = produce(
                    menus[menuToUpdate.id].components,
                    (draft) => {
                      menuToUpdate.components.forEach((component) => {
                        const index = draft.findIndex(
                          (c) => c.id === component.id
                        )

                        if (
                          index === -1 ||
                          draft[index].type !== 'mealWithExtra'
                        ) {
                          return
                        }

                        draft[index] = produce(
                          draft[index],
                          (componentDraft) => {
                            if (componentDraft.type === 'mealWithExtra') {
                              componentDraft.properties.mealOption.detailsMealID =
                                dialog.data.meal.id
                              componentDraft.properties.mealOption.meal.image =
                                {
                                  url,
                                }
                            }
                          }
                        )
                      })
                    }
                  )

                  updateMenu({
                    menu: {
                      components: updatedComponents,
                    },
                    menuID: menuToUpdate.id,
                  })
                })

                setDialog(null)
              }}
            />
          ) : dialog?.type === 'deleteMenus' ? (
            <DeleteMenusDialog
              data={dialog.data}
              onClose={() => {
                setDialog(null)
              }}
              onDeleteSuccess={() => {
                // Reset menus state with default menus data
                const defaultMenus: MenusState = {}

                orderedSubTerms.forEach((subTerm, index) => {
                  const mealSummaries = termMealSummaries[index]

                  const components = makeMenuComponentsStandardized({
                    meals: orderBy(mealSummaries, 'mainDisplayOrder'),
                    mealSwaps: subTerm.defaultMenu.mealSwaps,
                    suggestions: undefined,
                    specialEvent: subTerm.specialEvent || term.special_event,
                  })

                  defaultMenus[subTerm.id] = {
                    components,
                    description: `${
                      FACILITY_NETWORKS[subTerm.facilityNetwork]
                    } ${subTerm.shipPeriod} - Core`,
                    id: subTerm.id,
                    isDefault: true,
                    serverComponents: [],
                    subTermID: subTerm.id,
                  }
                })

                replaceAllMenus({ menus: defaultMenus })

                setDialog(null)
              }}
            />
          ) : dialog?.type === 'createMenus' ? (
            <UpdateMenusDialog
              data={dialog.data}
              onClose={() => {
                setDialog(null)
              }}
            />
          ) : dialog?.type === 'overwriteMenus' ? (
            <UpdateMenusDialog
              data={dialog.data}
              onClose={() => {
                setDialog(null)
              }}
            />
          ) : dialog?.type === 'tpsotImport' ? (
            <TPSOTImportDialog
              handleDrop={handleTPSOTImport}
              onClose={() => {
                setDialog(null)
              }}
            >
              <p>
                Drop the term's TPSoT CSV file below to create the menus defined
                in the TPSoT file. Alternately, click Cancel to use Core menus
                only.
              </p>
            </TPSOTImportDialog>
          ) : dialog?.type === 'tpsotReimport' ? (
            <TPSOTImportDialog
              handleDrop={handleTPSOTReimport}
              onClose={() => {
                setDialog(null)
              }}
            >
              <p>
                Drop the term's TPSoT CSV file below to overwrite the existing
                menus.
              </p>
            </TPSOTImportDialog>
          ) : null}
        </>
      ) : null}

      <ActiveComponentDragOverlay
        activeComponent={activeComponent}
        activeID={activeID}
        dragOverlayAddComponentType={dragOverlayAddComponentType}
        viewType={viewType}
      />
    </DndContext>
  )
}
