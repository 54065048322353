import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastProvider } from 'contexts/toast'
import * as Sentry from '@sentry/react'
import { setupAPI as setupCDNAPI } from '@tovala/browser-apis-cdn'
import { setupAPI as setupCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { setupAPI as setupMenuDeliveryAPI } from '@tovala/browser-apis-menu-delivery'

import { getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'
import { queryClient } from 'utils/api'
import { store } from './store'

import 'react-table/react-table.css'
import './index.css'
import 'apiRequests'
import { AuthProvider } from 'contexts/auth'
import App from './components/App'
import H1 from 'components/common/H1'

Sentry.init({
  dsn: getEnvVar('SENTRY_DSN'),
  environment: getEnvVar('APP_ENV'),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const apiOpts = {
  appID: 'Glaze',
  apiType: getEnvVar('APP_ENV') === 'production' ? 'prod' : 'dev',
  getBearerToken: () => getCookie('JWT_TOKEN'),
} as const
setupCombinedAPI({
  appID: apiOpts.appID,
  domain: getEnvVar('COMBINED_API_URL'),
  getBearerToken: apiOpts.getBearerToken,
})
setupCDNAPI({ apiType: apiOpts.apiType })
setupMenuDeliveryAPI(apiOpts)

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={
        <div>
          <H1>Oops!</H1>
          <p>An error has occured. Please refresh the page.</p>
        </div>
      }
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <ToastProvider>
                <App />
              </ToastProvider>
            </AuthProvider>
          </BrowserRouter>
        </Provider>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
)
