import { Modal, ModalHeader, Button } from '@tovala/component-library'
import FileDropzone from 'components/common/FileDropzone'
import { ReactNode } from 'react'

const TPSOTImportDialog = ({
  children,
  handleDrop,
  onClose,
}: {
  children: ReactNode
  handleDrop(files: File[]): Promise<void>
  onClose(): void
}) => {
  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Import TPSoT CSV</ModalHeader>
      <div className="space-y-10 p-6 w-[500px]">
        {children}
        <FileDropzone
          accept={{ 'text/csv': ['.csv'] }}
          maxFiles={1}
          onDrop={handleDrop}
        />
        <div className="flex justify-center">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default TPSOTImportDialog
