import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ReactNode } from 'react'

const SortableComponent = ({
  children,
  cols,
  componentID,
  componentType,
  isDragging = false,
}: {
  children: ReactNode
  cols?: string
  componentID: string
  componentType: string | null
  isDragging?: boolean
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: componentID,
      data: {
        componentType,
      },
    })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      ref={setNodeRef}
      className={`h-full w-full cursor-grab ${cols}`}
      style={style}
      {...attributes}
      {...listeners}
    >
      {!isDragging && children}
    </div>
  )
}

export default SortableComponent
