import {
  DeleteDefaultMenuResponse,
  DeleteMenuVariantsResponse,
  useDeleteDefaultMenu,
  useDeleteMenuVariants,
} from '@tovala/browser-apis-menu-delivery'
import {
  Modal,
  ModalHeader,
  APIErrorDisplay,
  Button,
  ButtonLoading,
} from '@tovala/component-library'
import { DELETE_DEFAULT_MENU_ERRORS } from 'utils/errors'
import { MenusState } from '../hooks'

const DeleteMenusDialog = ({
  data,
  onClose,
  onDeleteSuccess,
}: {
  data: {
    menus: MenusState
    subTermIDs: string[]
    termID: number
  }
  onClose(): void
  onDeleteSuccess(): void
}) => {
  const {
    mutateAsync: deleteDefaultMenuAsync,
    error: deleteDefaultMenuError,
    isError: hasDeleteDefaultMenuError,
    isLoading: isDeletingDefaultMenu,
  } = useDeleteDefaultMenu()

  const { mutateAsync: deleteMenuVariantsAsync } = useDeleteMenuVariants()

  const handleDeleteAll = async () => {
    try {
      const allMutations: (
        | Promise<DeleteMenuVariantsResponse>
        | Promise<DeleteDefaultMenuResponse>
      )[] = []
      data.subTermIDs.forEach((subTermID) => {
        allMutations.push(deleteDefaultMenuAsync({ subTermID }))
        allMutations.push(deleteMenuVariantsAsync({ subTermID }))
      })
      await Promise.all(allMutations)

      // All mutations have successfully completed
      onDeleteSuccess()
    } catch (error) {
      // TODO Handle errors
      console.error('Error during mutations:', error)
    }
  }

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Delete All Menus</ModalHeader>
      <div className="space-y-10 p-6 w-[500px]">
        <div className="text-center">
          Are you sure you want to delete all menus for <br />
          Term #{data.termID}?
        </div>

        {hasDeleteDefaultMenuError && (
          <APIErrorDisplay
            error={deleteDefaultMenuError}
            errorCodeMessageMap={DELETE_DEFAULT_MENU_ERRORS}
          />
        )}

        <div className="flex justify-between">
          <Button buttonStyle="stroke" onClick={onClose} size="large">
            Cancel
          </Button>
          <ButtonLoading
            buttonStyle="dark"
            isLoading={isDeletingDefaultMenu}
            onClick={handleDeleteAll}
            size="large"
          >
            Delete Menus
          </ButtonLoading>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteMenusDialog
