import { UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { MenuComponentStandardized } from '@tovala/browser-apis-menu-components'
import { ButtonRound } from '@tovala/component-library'
import { clsx } from 'clsx'
import ArrownDownIcon from 'components/common/icons/ArrownDownIcon'
import ArrowUpIcon from 'components/common/icons/ArrowUpIcon'
import ComponentToolbar from './ComponentToolbar'
import { DraggableNewComponentProps } from './DraggableNewComponent'
import BackgroundImageHeader from './menuComponents/BackgroundImageHeader'
import Meal from './menuComponents/Meal'
import MealCarousel from './menuComponents/MealCarousel'
import MealWithExtra from './menuComponents/MealWithExtra'
import TextBanner from './menuComponents/TextBanner'
import TextImageStack from './menuComponents/TextImageStack'
import TwoMealPicker from './menuComponents/TwoMealPicker'
import MenuGridLayout from './MenuGridLayout'
import SortableComponent from './SortableComponent'
import { ComponentDropPlaceholder, ViewType } from './utils'

const MenuComponentsGrid = ({
  activeID,
  components,
  onClickCopy,
  onClickDelete,
  onClickEdit,
  onReorderComponents,
  viewType,
}: {
  activeID: UniqueIdentifier | null
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  onClickCopy(opts: {
    component: MenuComponentStandardized
    index: number
  }): void
  onClickDelete(component: MenuComponentStandardized): void
  onClickEdit(opts: {
    componentType: DraggableNewComponentProps['componentType']
    data: { component: MenuComponentStandardized; index: number }
  }): void
  onReorderComponents(currentIndex: number, newIndex: number): void
  viewType: ViewType
}) => {
  return (
    <SortableContext items={components} strategy={undefined}>
      <div className="pt-2">
        <MenuGridLayout viewType={viewType}>
          {components.map((component, index) => {
            if (component.type === 'dropPlaceholder') {
              return (
                <div
                  key={index}
                  className={clsx(
                    'flex h-full min-h-[350px] w-full items-center justify-center rounded-2xl border border-dashed border-grey-6 col-span-1',
                    {
                      'col-span-2':
                        viewType === 'desktop' &&
                        component.properties.componentType === 'textImageStack',
                    }
                  )}
                >
                  Drop Component Here
                </div>
              )
            }

            return (
              <SortableComponent
                key={component.id}
                cols={
                  // We only have two columns on desktop. On mobile it's a single column
                  // so everything stretches just one column.
                  (component.type === 'textImageStack' ||
                    component.type === 'textBanner') &&
                  viewType === 'desktop'
                    ? 'col-span-2'
                    : 'col-span-1'
                }
                componentID={component.id}
                componentType={component.type}
                isDragging={activeID === component.id}
              >
                {component.type === 'backgroundImageHeader' ? (
                  <div className="group relative h-full min-h-[24rem] w-full">
                    <BackgroundImageHeader properties={component.properties} />

                    <div className="absolute bottom-0 left-0 right-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                      <ComponentToolbar
                        onClickCopy={() => {
                          onClickCopy({ component, index })
                        }}
                        onClickDelete={() => {
                          onClickDelete(component)
                        }}
                        onClickEdit={() => {
                          onClickEdit({
                            componentType: 'backgroundImageHeader',
                            data: { component, index },
                          })
                        }}
                      />
                    </div>
                  </div>
                ) : component.type === 'meal' ? (
                  <Meal properties={component.properties} />
                ) : component.type === 'mealWithExtra' ? (
                  <div className="group relative">
                    <MealWithExtra properties={component.properties} />

                    <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 rounded-b-2xl bg-grey-3/60 py-4 group-hover:flex">
                      <ComponentToolbar
                        onClickEdit={() => {
                          onClickEdit({
                            componentType: 'mealWithExtra',
                            data: { component, index },
                          })
                        }}
                      />
                    </div>
                  </div>
                ) : component.type === 'twoMealPicker' ? (
                  <div className="md:px-4">
                    <TwoMealPicker properties={component.properties} />
                  </div>
                ) : component.type === 'animatedMealCarousel' ? (
                  <MealCarousel properties={component.properties} />
                ) : component.type === 'textBanner' ? (
                  <TextBanner properties={component.properties} />
                ) : component.type === 'textImageStack' ? (
                  <div className="group relative col-span-2 md:col-span-1 hover:outline-offset-4 hover:outline-dashed hover:outline-grey-6">
                    <div className="absolute left-0 right-0 top-0 z-10 hidden justify-center space-x-4 bg-grey-3/60 py-4 group-hover:flex">
                      <ComponentToolbar
                        onClickCopy={() => {
                          onClickCopy({ component, index })
                        }}
                        onClickDelete={() => {
                          onClickDelete(component)
                        }}
                        onClickEdit={() => {
                          onClickEdit({
                            componentType: 'textImageStack',
                            data: { component, index },
                          })
                        }}
                      >
                        <>
                          {index > 0 && (
                            <ButtonRound
                              buttonSize="large"
                              buttonStyle="dark"
                              icon={<ArrowUpIcon />}
                              label="Move up"
                              onClick={() => {
                                const newIndex = index - 1
                                onReorderComponents(index, newIndex)
                              }}
                              title="Move up"
                            />
                          )}
                          {index + 1 < components.length && (
                            <ButtonRound
                              buttonSize="large"
                              buttonStyle="dark"
                              icon={<ArrownDownIcon />}
                              label="Move down"
                              onClick={() => {
                                const newIndex = index + 1
                                onReorderComponents(index, newIndex)
                              }}
                              title="Move down"
                            />
                          )}
                        </>
                      </ComponentToolbar>
                    </div>

                    <TextImageStack
                      properties={component.properties}
                      viewType={viewType}
                    />
                  </div>
                ) : null}
              </SortableComponent>
            )
          })}
        </MenuGridLayout>
      </div>
    </SortableContext>
  )
}

export default MenuComponentsGrid
