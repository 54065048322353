import { MealAdmin } from '@tovala/browser-apis-combinedapi'
import { MenuComponentStandardizedMealWithExtra } from '@tovala/browser-apis-menu-components'
import { Modal, ModalHeader, Checkbox, Button } from '@tovala/component-library'
import { produce } from 'immer'
import { useState } from 'react'

export interface DialogData {
  meal: MealAdmin
  menus: Record<
    string,
    {
      components: MenuComponentStandardizedMealWithExtra[]
      description: string
      id: string
    }
  >
}

const EditAdditionalMealWithExtraDialog = ({
  data,
  onClose,
  onSave,
}: {
  data: DialogData
  onClose(): void
  onSave(menusToUpdate: DialogData['menus']): void
}) => {
  const { meal, menus } = data
  const [menusToUpdate, setMenusToUpdate] = useState<DialogData['menus']>(menus)

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>
        Additional Matching Extras
      </ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          onSave(menusToUpdate)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <p>Select the components you'd like to update with {meal.title}.</p>
            {Object.values(menus).map((menu) => {
              return (
                <div key={menu.id} className="space-y-4">
                  <div className="text-k/12_120 uppercase text-black">
                    {menu.description}
                  </div>

                  <div className="space-y-2">
                    {menu.components.map((component) => {
                      const isChecked = menusToUpdate[menu.id].components
                        .map((component) => component.id)
                        .includes(component.id)

                      return (
                        <div key={component.id}>
                          <Checkbox
                            checked={isChecked}
                            label={
                              <div className="flex items-center space-x-4">
                                <img
                                  className="h-16 rounded-md"
                                  src={
                                    component.properties.mealOption.meal.image
                                      .url
                                  }
                                />
                                <div>
                                  <div>
                                    {component.properties.mealOption.meal.title}
                                  </div>
                                  <div className="text-sm">
                                    {
                                      component.properties.mealOption.meal
                                        .subtitle
                                    }
                                  </div>
                                </div>
                              </div>
                            }
                            name={component.id}
                            onChange={() => {
                              setMenusToUpdate((prevMenusToUpdate) => {
                                const updatedComponents = isChecked
                                  ? prevMenusToUpdate[
                                      menu.id
                                    ].components.filter(
                                      (c) => c.id !== component.id
                                    )
                                  : [
                                      ...prevMenusToUpdate[menu.id].components,
                                      component,
                                    ]

                                return produce(prevMenusToUpdate, (draft) => {
                                  draft[menu.id].components = updatedComponents
                                })
                              })
                            }}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Save
            </Button>

            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditAdditionalMealWithExtraDialog
