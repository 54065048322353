import { MealAdmin, TermSubTerm } from '@tovala/browser-apis-combinedapi'
import {
  MenuComponentStandardized,
  MenuComponentStandardizedMeal,
  MenuComponentStandardizedTwoMealPicker,
  MenuComponentStandardizedMealCarousel,
  MenuComponentStandardizedMealWithExtra,
} from '@tovala/browser-apis-menu-components'
import { MenuComponents } from '@tovala/browser-apis-menu-delivery'
import { isEqual, sortBy } from 'lodash-es'

export interface ComponentDropPlaceholder {
  id: string
  properties: {
    componentType:
      | 'backgroundImageHeader'
      | 'mealWithExtra'
      | 'textImageStack'
      | null
  }
  type: 'dropPlaceholder'
}

export type ViewType = 'desktop' | 'mobile'

// Creates temporary component IDs that persist through data refetches
export function getComponentTempID(component: MenuComponentStandardized) {
  if (component.type === 'meal') {
    return `TEMP-${component.properties.id}`
  } else if (component.type === 'animatedMealCarousel') {
    return `TEMP-${component.properties.mealOptions[0].id}`
  } else if (component.type === 'mealWithExtra') {
    return `TEMP-${component.properties.meal.id}`
  } else if (component.type === 'twoMealPicker') {
    return `TEMP-${component.properties.meals[0].id}`
  } else if (component.type === 'menuFeedback') {
    return 'TEMP-menuFeedback'
  } else if (component.type === 'textBanner') {
    return 'TEMP-textBanner'
  } else if (component.type === 'suggestions') {
    return 'TEMP-suggestions'
  } else if (component.type === 'backgroundImageHeader') {
    return 'TEMP-backgroundImageHeader'
  }

  return 'TEMP'
}

export function getValidImageURL(url: string) {
  if (!url) {
    return url
  }

  return !url.startsWith('http') ? `https:${url}` : url
}

export function getMealImageURL(meal: MealAdmin) {
  let url = ''
  const image = meal.images.find((image) => image.key === 'cell_tile')
  if (image) {
    url = getValidImageURL(image.url)
  }
  return url
}

export function isMealComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMeal {
  return component?.type === 'meal'
}

export function isTwoMealPickerComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedTwoMealPicker {
  return component?.type === 'twoMealPicker'
}

export function isMealCarouselComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMealCarousel {
  return component?.type === 'animatedMealCarousel'
}

export function isMealWithExtraComponent(
  component: MenuComponentStandardized | ComponentDropPlaceholder | undefined
): component is MenuComponentStandardizedMealWithExtra {
  return component?.type === 'mealWithExtra'
}

export function validateMealSwaps({
  components,
  subTerm,
}: {
  components: MenuComponents['components']
  subTerm: TermSubTerm
}) {
  const componentMealSwaps = sortBy(
    components
      .filter(
        (component) =>
          component.type === 'mealWithExtra' ||
          component.type === 'animatedMealCarousel' ||
          component.type === 'textImageStack'
      )
      .flatMap((component) => {
        if (component.type === 'mealWithExtra') {
          return {
            mealID: component.properties.meal.mealID,
            swapIDs: [component.properties.mealOption.meal.mealID],
          }
        } else if (component.type === 'animatedMealCarousel') {
          const [meal, ...swaps] = component.properties.mealOptions
          return {
            mealID: meal.mealID,
            swapIDs: swaps.map((swap) => swap.mealID),
          }
        } else if (component.type === 'textImageStack') {
          return component.properties.children
            .filter(
              (child) =>
                child.type === 'mealWithExtra' ||
                child.type === 'animatedMealCarousel'
            )
            .map((child) => {
              if (child.type === 'mealWithExtra') {
                return {
                  mealID: child.properties.meal.mealID,
                  swapIDs: [child.properties.mealOption.meal.mealID],
                }
              } else if (child.type === 'animatedMealCarousel') {
                const [meal, ...swaps] = child.properties.mealOptions
                return {
                  mealID: meal.mealID,
                  swapIDs: swaps.map((swap) => swap.mealID),
                }
              }
            })
        }
      }),
    (mealSwap) => mealSwap?.mealID
  )
  const termMealSwaps = sortBy(
    subTerm.defaultMenu.mealSwaps,
    (mealSwap) => mealSwap.mealID
  )

  return isEqual(componentMealSwaps, termMealSwaps)
}
