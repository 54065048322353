import { useParams } from 'react-router-dom'
import MenuEditor from './MenuEditor'
import { useTermMealSummaries } from 'hooks/combinedAPI/menus'
import { MealSummary, useTerm } from '@tovala/browser-apis-combinedapi'
import Loader from 'components/common/Loader'
import { orderBy } from 'lodash-es'
import { APIErrorDisplay } from '@tovala/component-library'
import { LOAD_TERM_ERRORS } from 'utils/errors'

const MenuEditorPage = () => {
  const { termID: termIDParam } = useParams<{ termID: string }>()
  const termID = Number.isNaN(Number(termIDParam))
    ? undefined
    : Number(termIDParam)

  const {
    data: term,
    error: loadTermError,
    isLoading: isLoadingTerm,
  } = useTerm({ termID })
  const subTerms = term?.subTerms ?? []
  const orderedSubTerms = orderBy(subTerms, ['facilityNetwork', 'shipPeriod'])

  const termMealSummariesResponse = useTermMealSummaries({
    subTermIDs: orderedSubTerms.map((subTerm) => subTerm.id),
  })
  const termMealSummaries = termMealSummariesResponse
    .map((res) => res.data)
    .filter((mealSummaries): mealSummaries is MealSummary[] => !!mealSummaries)
  const isLoadingTermMealSummaries = termMealSummariesResponse.some(
    (res) => res.isLoading
  )
  const loadTermMealSummariesError =
    termMealSummariesResponse.find((res) => res.error)?.error ?? null

  if (isLoadingTerm || isLoadingTermMealSummaries) {
    return <Loader />
  }

  if (loadTermError || loadTermMealSummariesError) {
    return (
      <APIErrorDisplay
        display="page"
        error={loadTermError || loadTermMealSummariesError}
        errorCodeMessageMap={LOAD_TERM_ERRORS}
      />
    )
  }

  return (
    <MenuEditor
      orderedSubTerms={orderedSubTerms}
      term={term}
      termMealSummaries={termMealSummaries}
    />
  )
}

export default MenuEditorPage
