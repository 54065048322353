import {
  DndContext,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { MenuComponentStandardized } from '@tovala/browser-apis-menu-components'
import {
  Button,
  ButtonLoading,
  ButtonRound,
  FormFieldError,
  FormGroup,
  Input,
  Listbox,
  Modal,
  ModalHeader,
  Textarea,
  TrashIcon,
  XIcon,
} from '@tovala/component-library'
import { compact, findIndex } from 'lodash-es'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ImageUpload, { ImageFormData } from '../ImageUpload'
import SortableComponent from '../SortableComponent'
import { ComponentDropPlaceholder } from '../utils'

interface TextImageStackFormData {
  associatedComponentIDs: Set<string>
  image?: ImageFormData
  subtitle?: string
  title: string
}

const TextImageStackDialog = ({
  components,
  initialValues,
  isUploadingImage,
  onClose,
  onSave,
}: {
  components: (ComponentDropPlaceholder | MenuComponentStandardized)[]
  initialValues: TextImageStackFormData | undefined
  isUploadingImage: boolean
  onClose(): void
  onSave(data: TextImageStackFormData): void
}) => {
  const { control, formState, handleSubmit, register, setValue, watch } =
    useForm<TextImageStackFormData>({
      defaultValues: initialValues,
    })

  const associatedComponentIDs = watch('associatedComponentIDs')
  const image = watch('image')

  const associatedMealsOptions = compact(
    components.map((component) => {
      // If the component has already been chosen as an associated component,
      // don't display it as an option to choose again.
      if (associatedComponentIDs.has(component.id)) {
        return
      }

      if (component.type === 'meal') {
        return {
          label: component.properties.title,
          value: component.id,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          label: component.properties.meal.title,
          value: component.id,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          label: component.properties.meals[0].title,
          value: component.id,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          label: component.properties.mealOptions[0].title,
          value: component.id,
        }
      }
    })
  )

  // Some components have children that could be meal components we want to
  // search through to display a preview. This will happen with an existing
  // textImageStack - we want to show that the children meals have been chosen.
  const flatComponents = components.flatMap((component) => {
    if (component.type === 'textImageStack') {
      return component.properties.children
    }

    return component
  })

  const associatedMealPreviews = compact(
    Array.from(associatedComponentIDs).map((componentID) => {
      const component = flatComponents.find(
        (component) => component.id === componentID
      )
      if (!component) {
        return
      }

      if (component.type === 'meal') {
        return {
          id: component.id,
          image: component.properties.image,
          subtitle: component.properties.subtitle,
          title: component.properties.title,
        }
      } else if (component.type === 'mealWithExtra') {
        return {
          id: component.id,
          image: component.properties.meal.image,
          subtitle: component.properties.meal.subtitle,
          title: component.properties.meal.title,
        }
      } else if (component.type === 'twoMealPicker') {
        return {
          id: component.id,
          image: component.properties.meals[0].image,
          subtitle: component.properties.meals[0].subtitle,
          title: component.properties.meals[0].title,
        }
      } else if (component.type === 'animatedMealCarousel') {
        return {
          id: component.id,
          image: component.properties.mealOptions[0].image,
          subtitle: component.properties.mealOptions[0].subtitle,
          title: component.properties.mealOptions[0].title,
        }
      }
    })
  )

  const [activeID, setActiveID] = useState<UniqueIdentifier | null>(null)
  const getIndex = (id: UniqueIdentifier) =>
    findIndex(associatedMealPreviews, (component) => component.id === id)
  const activeIndex = activeID ? getIndex(activeID) : -1

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the component.
      activationConstraint: {
        distance: 10,
      },
    })
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Text Image Stack</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="grid grid-cols-[1fr_120px] gap-4">
              <FormGroup label="Image">
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => (
                    <ImageUpload
                      error={formState.errors.image?.message}
                      hasError={!!formState.errors.image}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      onImageAdded={(data) => {
                        setValue('image', data, { shouldValidate: true })
                      }}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup label="Preview">
                <div className="group relative">
                  <img src={image?.src} />

                  <div className="absolute inset-0 hidden items-center justify-center bg-grey-3/60 py-4 group-hover:flex">
                    <ButtonRound
                      buttonSize="small"
                      buttonStyle="dark"
                      icon={<TrashIcon />}
                      label="Delete"
                      onClick={() => {
                        setValue('image', undefined)
                      }}
                    />
                  </div>
                </div>
              </FormGroup>

              <div className="col-span-2">
                <FormGroup
                  error={formState.errors.title?.message}
                  label="Title"
                >
                  <Input
                    hasError={!!formState.errors.title}
                    type="text"
                    {...register('title', { required: 'Please enter a title' })}
                  />
                </FormGroup>
              </div>
              <div className="col-span-2">
                <FormGroup label="Subtitle">
                  <Textarea rows={4} {...register('subtitle')} />
                </FormGroup>
              </div>
              <div className="col-span-2 space-y-4">
                <FormGroup label="Associated Meals">
                  <Controller
                    control={control}
                    name="associatedComponentIDs"
                    render={() => (
                      <div className="space-y-1">
                        <Listbox
                          onChange={(option) => {
                            if (option) {
                              const newAssociatedComponentIDs = new Set(
                                associatedComponentIDs
                              )
                              newAssociatedComponentIDs.add(option.value)

                              setValue(
                                'associatedComponentIDs',
                                newAssociatedComponentIDs,
                                { shouldValidate: true }
                              )
                            }
                          }}
                          options={associatedMealsOptions}
                          value={null}
                        />

                        {formState.errors.associatedComponentIDs?.message && (
                          <FormFieldError>
                            {formState.errors.associatedComponentIDs.message}
                          </FormFieldError>
                        )}
                      </div>
                    )}
                    rules={{
                      required: 'Please choose associated meals',
                    }}
                  />
                </FormGroup>

                <DndContext
                  onDragEnd={() => {
                    setActiveID(null)
                  }}
                  onDragOver={({ over }) => {
                    if (!over) {
                      return
                    }

                    const overIndex = getIndex(over.id)

                    if (activeIndex !== overIndex) {
                      const updatedOrder = arrayMove(
                        Array.from(associatedComponentIDs),
                        activeIndex,
                        overIndex
                      )
                      setValue('associatedComponentIDs', new Set(updatedOrder))
                    }
                  }}
                  onDragStart={({ active }) => {
                    if (!active) {
                      return
                    }

                    setActiveID(active.id)
                  }}
                  sensors={sensors}
                >
                  <SortableContext
                    items={associatedMealPreviews}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="grid grid-cols-1 gap-y-4">
                      {associatedMealPreviews.map(
                        ({ id, image, subtitle, title }) => {
                          return (
                            <SortableComponent
                              key={id}
                              componentID={id}
                              componentType={null}
                            >
                              <div
                                key={id}
                                className="flex items-center justify-between space-x-4"
                              >
                                <div className="flex items-center space-x-4">
                                  <img
                                    className="h-16 rounded-md"
                                    src={image.url}
                                  />
                                  <div>
                                    <div>{title}</div>
                                    <div className="text-sm">{subtitle}</div>
                                  </div>
                                </div>

                                <button
                                  className="h-5 w-5 shrink-0"
                                  onClick={() => {
                                    const newAssociatedComponentIDs = new Set(
                                      associatedComponentIDs
                                    )
                                    newAssociatedComponentIDs.delete(id)

                                    setValue(
                                      'associatedComponentIDs',
                                      newAssociatedComponentIDs
                                    )
                                  }}
                                  type="button"
                                >
                                  <XIcon />
                                </button>
                              </div>
                            </SortableComponent>
                          )
                        }
                      )}
                    </div>
                  </SortableContext>
                </DndContext>
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <ButtonLoading
              isLoading={isUploadingImage}
              size="large"
              type="submit"
            >
              Save
            </ButtonLoading>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default TextImageStackDialog
