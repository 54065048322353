import { Modal, ModalHeader, Checkbox, Button } from '@tovala/component-library'
import { useState } from 'react'

const CopyComponentDialog = ({
  onClose,
  onSave,
  menus,
}: {
  onClose(): void
  onSave(subTermIDs: Set<string>): void
  menus: { id: string; description: string }[]
}) => {
  const [menuIDs, setMenuIDs] = useState(
    new Set<string>(menus.map((menu) => menu.id))
  )

  return (
    <Modal onCloseModal={onClose}>
      <ModalHeader onClickClose={onClose}>Copy to Other Menus</ModalHeader>
      <form
        onSubmit={(event) => {
          event.preventDefault()

          onSave(menuIDs)
        }}
      >
        <div className="p-6 font-sans-new">
          <div className="w-[500px] space-y-4">
            <div className="w-min">
              {menuIDs.size === menus.length ? (
                <Button
                  buttonStyle="link"
                  onClick={() => {
                    setMenuIDs(new Set())
                  }}
                  size="fluid"
                >
                  Deselect All
                </Button>
              ) : (
                <Button
                  buttonStyle="link"
                  onClick={() => {
                    setMenuIDs(new Set<string>(menus.map((menu) => menu.id)))
                  }}
                  size="fluid"
                >
                  Select All
                </Button>
              )}
            </div>

            {menus.map((menu) => {
              const label = menu.description || menu.id

              return (
                <div key={menu.id} className="flex">
                  <Checkbox
                    checked={menuIDs.has(menu.id)}
                    label={
                      <span className="text-k/13_120 uppercase">{label}</span>
                    }
                    name={label}
                    onChange={() => {
                      setMenuIDs((prevMenuIDs) => {
                        const newMenuIDs = new Set(prevMenuIDs)

                        if (newMenuIDs.has(menu.id)) {
                          newMenuIDs.delete(menu.id)
                        } else {
                          newMenuIDs.add(menu.id)
                        }

                        return newMenuIDs
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>

          <div className="mt-8 flex flex-row-reverse gap-4">
            <Button size="large" type="submit">
              Copy
            </Button>
            <Button
              buttonStyle="stroke"
              onClick={() => {
                onClose()
              }}
              size="large"
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default CopyComponentDialog
